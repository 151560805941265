import { createWebHistory, createRouter } from "vue-router";
import EssayComponent from "../components/EssayComponent.vue";
import ExploreGrid from "../components/ExploreGrid.vue"
import ManualComponent from "../components/ManualComponent.vue"
import AboutComponent from "../components/AboutComponent.vue"
// import isMobile from '@/mixins/ismobile';

const routes = [
  {
    path: '/explorer',
    component: ExploreGrid,
    meta: {
      title: 'Image Explorer – Defining the Deep Blue Sublime'
    }
  },
  {
    path: '/manual',
    component: ManualComponent,
    meta: {
      title: 'Manual – Defining the Deep Blue Sublime'
    }
  },
  {
    path: '/about',
    component: AboutComponent,
    meta: {
      title: 'About – Defining the Deep Blue Sublime'
    }
  },
  {
    path: '',
    component: EssayComponent,
    name: 'Defining the Deep Blue Sublime',
    meta: { title: 'Essay – Defining the Deep blue Sublime' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to) {
  //   if (!to.hash) {
  //     setTimeout(() => {
  //       return { left: 0, top: 0, behavior: "smooth" };
  //     }, 500);
  //     // TODO: fix this to properly accommodate hadhtags
  //   }
  // }
})


router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  if (!to.hash) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 350);
    // TODO: fix this to properly accommodate hadhtags
  }

  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // Continue resolving the route
  next()
})

export default router