import "./assets/reset.css";
import { createApp, ref } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'

import ImageView from './components/ImageView.vue'


import { plugin as VueTippy } from 'vue-tippy'

const app = createApp(App);

app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      theme: 'light-border'
    }, // => Global default options * see all props
  }
)

app.component('image-view', ImageView).use(router)

// This function checks whether the screen width matches mobile 
// It's a ref, so remember to add ".value" to access it as a reactive property or data within the components.
app.config.globalProperties.breakpoints = {
  sm: 768,
  md: 1024,
  lg: 1200,
};

app.config.globalProperties.winWidth = ref(window.innerWidth);

app.config.globalProperties.winWidth = ref(window.innerWidth);
app.config.globalProperties.screenMobile = ref(
  window.innerWidth < app.config.globalProperties.breakpoints.sm
);

window.addEventListener("resize", function () {
  app.config.globalProperties.winWidth.value = window.innerWidth;
  app.config.globalProperties.screenMobile.value =
    app.config.globalProperties.winWidth.value <
    app.config.globalProperties.breakpoints.sm;
});

app.mount('#app');