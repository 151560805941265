<template>
  <div class="carousel-wrapper">
    <div class="imageCarousel">
      <img
        v-for="img in currentJson.slice(0, 128)"
        :src="require(`@/assets/google/${currentKeyword}/img-database/${img.name}`)"
        alt=""
        :key=img.name
      >
    </div>
  </div>
</template>
<script>
import aiJson from "../../src/assets/google/artificial-intelligence/img-names.json";
import bitcoinJson from "../../src/assets/google/bitcoin/img-names.json";
import cloudJson from "../../src/assets/google/cloud-computing/img-names.json";
import kiJson from "../../src/assets/google/kunstmatige-intelligentie/img-names.json";
import hackerJson from "../../src/assets/google/hacker/img-names.json";
import privacyJson from "../../src/assets/google/privacy/img-names.json";
import technologyJson from "../../src/assets/google/technology/img-names.json";
import robotJson from "../../src/assets/google/robot/img-names.json";

export default {
  props: {
    currentKeyword: String,
  },
  data() {
    return {
      currentJson: []
    }
  },
  created() {
    if (this.currentKeyword === "artificial-intelligence") this.currentJson = aiJson;
    if (this.currentKeyword === "bitcoin") this.currentJson = bitcoinJson;
    if (this.currentKeyword === "cloud-computing") this.currentJson = cloudJson;
    if (this.currentKeyword === "kunstmatige-intelligentie") this.currentJson = kiJson;
    if (this.currentKeyword === "hacker") this.currentJson = hackerJson;
    if (this.currentKeyword === "privacy") this.currentJson = privacyJson;
    if (this.currentKeyword === "technology") this.currentJson = technologyJson;
    if (this.currentKeyword === "robot") this.currentJson = robotJson;
  },
  computed: {
    sliceArray(array) {
      return array.slice(0, 10);
    },
  }
}
</script>
<style>
.carousel-wrapper {
  overflow-x: scroll;
  position: relative;
}

.imageCarousel {
  display: grid;
  grid-auto-columns: 100px;
  grid-template-rows: repeat(2, 100px);
  gap: 5px;
  position: relative;
  transform: translateX(-50%);
  grid-auto-flow: column;
  /* width: 140%; */
  /* position: relative; */
  /* margin-left: -20%; */
  /* max-width: 100vw; */
}

.imageCarousel img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: transform .2s;
}

.imageCarousel img:hover {
  transform: scale(1.5);
}



/* p {
  color: red;
} */
</style>