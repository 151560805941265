<template>
  <div
    class="article-wrapper"
    ref="article"
  >
    <header class="essay-header">
      <h1 ref="header">Defining the Deep Blue Sublime</h1>
      <span>By Dominik Vrabič Dežman with SETUP</span>
      <span>20 April 2023</span>
      <div
        class="backdrop-top"
        :style="{ backgroundImage: getBackgroundGradient }"
      ></div>
    </header>

    <section>
      <nav
        class="table-of-contents"
        id="toc"
      >
        <h2>Table of Contents</h2>
        <ol>
          <a href="#introduction">
            <li>
              <p>Introduction</p>
            </li>
          </a>
          <a href="#images-and-google-images">
            <li>
              <p>Images and Google Images</p>
            </li>
          </a>
          <a href="#charting-the-waters">
            <li>
              <p>Charting the Waters</p>
              <p>Approach</p>
            </li>
          </a>
          <a href="#diving-into-the-deep">
            <li>
              <p>Diving into the Deep</p>
              <p>Findings</p>
            </li>
          </a>
          <a href="#properties-of-the-deep-blue-sublime">
            <li>
              <p><em>(Some) Properties of the Deep Blue Sublime</em></p>
            </li>
          </a>
          <a href="#looking-at-the-keywords">
            <li>
              <p>Looking at the Keywords</p>
            </li>
          </a>
          <a href="#making-sense-of-the-results">
            <li>
              <p>Making Sense of the Results</p>
              <p>Reflection and Limitations</p>
            </li>
          </a>
          <a href="#where-will-we-go-with-these-images">
            <li>
              <p>Where Will We Go with These Images?</p>
              <p>Future Urgencies</p>
            </li>
          </a>
          <a href="#conclusion">
            <li>
              <p>Conclusion</p>
            </li>
          </a>
          <a href="#awareness-checklist">
            <li>
              <p><em>Deep Blue Sublime Awareness Checklist</em></p>
            </li>
          </a>
        </ol>
      </nav>
    </section>
    <article>
      <section>
        <h2 id="introduction">Introduction</h2>
        <p>One day, in the late autumn of 2019, browsing the web as one often does, curiosity got the best of me, and I
          decided to query Google Images for the keyword “Artificial Intelligence”. The results returned back to me were
          strange and uncanny. I decided to dive further to research this phenomenon, and I realised that I wasn’t alone.
          Some of my first research became a brief book, <em>Illuminating the Deep Blue Sublime</em>,<a
            href="#fn1"
            class="footnote-ref"
            id="fnref1"
            role="doc-noteref"
          ><sup>1</sup></a> in which I outline and discuss some major problems in the contemporary production of imagery
          used to represent AI, from the perspective of image-makers and visual communication designers such as myself.
          Other parts of it became my graduation work, <a
            href="https://graduation2021.kabk.nl/students/dominik-vrabic-dezman"
            class="outside-link"
            target="_blank"
          >Through the Societal Looking-Glass</a>,<a
            href="#fn2"
            class="footnote-ref"
            id="fnref2"
            role="doc-noteref"
          ><sup>2</sup></a> an installation and digital research method that can help us to track the lives and habits of
          these images on Google Images over time. This research continues as an ongoing exploration, suspended against
          the
          background of these past endeavours.</p>
        <figure>
          <img
            :src="require('@/assets/images/media/image1.png')"
            class="no-border"
          />
          <figcaption><span class="figlabel">Figure 1.</span> Google Image search results for query “artificial
            intelligence”. 3 January 2023.
          </figcaption>
        </figure>
        <p>In recent years, emergent general-purpose technologies such as Artificial Intelligence (AI), bitcoin, and cloud
          computing, have rapidly become part of our society and public discourse. In the media, images used to present,
          mediate, and promote (actively encourage) emergent tech to the public form a crucial part of this discourse. As
          an
          information designer and media scholar, I was initially interested in <strong>
            the lives and habits of these images: where
            do they come from, what are they doing, and where are they going?
          </strong> There is a growing body of scholarship
          speaking
          up about media images of emergent technology in the media and their problems. These images have been criticised
          for perpetuating prototypical whiteness,<a
            href="#fn3"
            class="footnote-ref"
            id="fnref3"
            role="doc-noteref"
          ><sup>3</sup></a> racism,<a
            href="#fn4"
            class="footnote-ref"
            id="fnref4"
            role="doc-noteref"
          ><sup>4</sup></a> sensationalised religious iconography<a
            href="#fn5"
            class="footnote-ref"
            id="fnref5"
            role="doc-noteref"
          ><sup>5</sup></a> and fearmongering. This research represents a small part in the growing effort to begin
          discussing the peculiar, and often misleading, nature of the images used to represent emergent tech, and keep
          these conversations earmarked and open, as they pose complex problems and definitely do not readily lead to
          clear-cut solutions.</p>
        <p>Within the goal of opening up the conversation by exploring the visual universe and political economy of these
          images, a central strategy has been to <strong>view these images as a collectivity, rather than individual
            artefacts</strong>. I
          wanted to explore the ways in which they forge their relationships with us. As I
          will continue to elaborate here, our encounter with these images often consists of awe and unknowability, a
          knowledge relationship I have previously coined the <em class="extra-emphasis">deep blue sublime</em>.<a
            href="#fn6"
            class="footnote-ref"
            id="fnref6"
            role="doc-noteref"
          ><sup>6</sup></a> Through this research, I aim to explore and examine in what ways the deep blue sublime
          manifests
          and presents itself in contemporary visual culture, focusing on the digital image economy of online media. As
          part
          of the broader collaboration with SETUP, I will explore the results returned on Google Images for keywords such
          as
          “artificial intelligence”, “bitcoin”, “cloud computing”, “hacker”, and “privacy”. <strong>My main approach has
            been to mobilise “search as research”,</strong><a
            href="#fn7"
            class="footnote-ref"
            id="fnref7"
            role="doc-noteref"
          ><sup>7</sup></a> <strong>and see what the results can tell</strong>. After collecting a representative sample
          of
          the images that appear in search results, I analysed the unique images for themes, tropes, and commonalities.
          You
          can read more on my methodology in chapter <a
            href="#charting-the-waters"
            class="page-link"
          >Charting the Waters</a>.</p>

        <ol
          class="footnotes"
          start="1"
        >
          <li
            id="fn1"
            role="doc-footnote"
          >
            <p>Dominik Vrabič Dežman, <em>Encountering the Deep Blue Sublime: Artificial Intelligence in the
                Media
                Imaginary</em>, 1st electronic ed (Tržič: self-published, 2022).<a
                href="#fnref1"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn2"
            role="doc-footnote"
          >
            <p>https://graduation2021.kabk.nl/students/dominik-vrabic-dezman<a
                href="#fnref2"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn3"
            role="doc-footnote"
          >
            <p>Stephen Cave and Kanta Dihal, ‘The Whiteness of AI’, <em>Philosophy &amp; Technology</em> 33,
              no. 4 (1
              December 2020): 685-703, https://doi.org/10.1007/s13347-020-00415-6.<a
                href="#fnref3"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn4"
            role="doc-footnote"
          >
            <p>Sabelo Mhlambi, ‘God in the Image of White Men: Creation Myths, Power Asymmetries and AI’,
              Sabelo Mhlambi,
              29 March 2019, /2019/03/29/God-in-the-image-of-white-men.<a
                href="#fnref4"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn5"
            role="doc-footnote"
          >
            <p>Beth Singler, ‘The AI Creation Meme: A Case Study of the New Visibility of Religion in
              Artificial
              Intelligence Discourse’, <em>Religions</em> 11, no. 5 (19 May 2020): 253,
              https://doi.org/10.3390/rel11050253.<a
                href="#fnref5"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn6"
            role="doc-footnote"
          >
            <p>Vrabič Dežman, <em>Encountering the Deep Blue Sublime.</em><a
                href="#fnref6"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn7"
            role="doc-footnote"
          >
            <p>Richard Rogers, <em>Doing Digital Methods</em>, 1st ed. (Los Angeles: SAGE, 2019), 104.<a
                href="#fnref7"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>
      </section>
      <section>
        <!-- SECTION -->
        <h2 id="images-and-google-images">Images and Google Images</h2>
        <blockquote>
          <p class="block-quote">“Symbolic forms are social forms.”<a
              href="#fn8"
              class="footnote-ref"
              id="fnref8"
              role="doc-noteref"
            ><sup>8</sup></a></p>
        </blockquote>
        <p>Images serve a crucial role in our society. They are powerful meaning-making devices, that gather us around
          their
          contents: “when we observe [images], we see concepts - encoded in a new way - of the world out there.”<a
            href="#fn9"
            class="footnote-ref"
            id="fnref9"
            role="doc-noteref"
          ><sup>9</sup></a> Images are windows that open onto a symbolic reality, an imagined and framed lifeworld that
          exists within them. Images are central building blocks of contemporary digital media. They often accompany news
          headlines and frame text, setting the stage and tone of the article. In that role, images employed to represent
          emergent tech to the public, inject into the public sphere prefabricated associations though which these
          technologies ought to be visually understood, prime and level our collective visions, and pave the path towards
          welcoming new technologies with pre-received meaning and feeling. Considering the crucial role of the media in
          forming public opinion about new tech,<a
            href="#fn10"
            class="footnote-ref"
            id="fnref10"
            role="doc-noteref"
          ><sup>10</sup></a> these images are far from innocent bystanders or passive background elements of our public
          visual world. Rather, they are social, dynamic, and living artefacts, reflecting and perpetuating the worldviews
          of their creators. <strong>These images animate a lively media image economy, and gradually settle into our
            collective imaginary, the social archive of our received meanings, common understandings, and future
            visions</strong>. The study of imaginaries can crucially help us in grasping the footprint and social
          trajectory
          of the emergent tech that appears to be rapidly proliferating all around us.<a
            href="#fn11"
            class="footnote-ref"
            id="fnref11"
            role="doc-noteref"
          ><sup>11</sup></a></p>
        <p>Nowadays, the visual world of the media largely lives online, where large search engines such as Google (with
          over 90% market capitalization in 2022)<a
            href="#fn12"
            class="footnote-ref"
            id="fnref12"
            role="doc-noteref"
          ><sup>12</sup></a> mark the beginning of many of our knowledge journeys, in which a quarter of users settle for
          the top search result.<a
            href="#fn13"
            class="footnote-ref"
            id="fnref13"
            role="doc-noteref"
          ><sup>13</sup></a> In that sense, <strong>search engines and their algorithms are important culture makers
            too</strong>: they are fundamentally entangled with our practices, and define “how people seek information,
          how they perceive and think about the contours of knowledge, and how they understand themselves in and through
          public discourse”.<a
            href="#fn14"
            class="footnote-ref"
            id="fnref14"
            role="doc-noteref"
          ><sup>14</sup></a> Given my observation that Google Images tends to privilege and prioritises news outlet
          websites
          (such as CNN, BBC, etc.), the infinitely scrollable result pages can be repurposed into a rich and dynamic index
          into the digital media image economy at a given point in time.</p>
        <p>On the main image search engines (Google Images, Yahoo, Bing, Yandex), a large number of the images returned
          are
          stock images that accompany the news articles that the search engine links to. Stock images have made a life for
          themselves inside the media environment - <strong>
            it is estimated that they comprise over seventy percent of all images
            used in Western public media
          </strong>.<a
            href="#fn15"
            class="footnote-ref"
            id="fnref15"
            role="doc-noteref"
          ><sup>15</sup></a> These generic images, made with no particular project in mind, are rented-out and licensed
          from
          massive stock image platforms such as Adobe Stock, ShutterStock, or iStockPhoto, and form the backbone of the
          “ambient image environment that defines our visual world”.<a
            href="#fn16"
            class="footnote-ref"
            id="fnref16"
            role="doc-noteref"
          ><sup>16</sup></a> By bombarding us with more of the same, stock images construct and stabilise the link between
          a
          keyword <em>qua</em> signifier (“artificial intelligence”) and signified (blue monochrome, omnipotence of AI,
          ruler of infinitely expanding cyberspace…). As generic images employed to talk on behalf of highly specific and
          rapidly evolving technologies, they speak from an impossible place.<a
            href="#fn17"
            class="footnote-ref"
            id="fnref17"
            role="doc-noteref"
          ><sup>17</sup></a> <strong>They employ yesterday’s metaphors to sketch out tomorrow’s technologies and work as
            shortcuts, always-late messengers of received wisdom and shared ideals</strong>.<a
            href="#fn18"
            class="footnote-ref"
            id="fnref18"
            role="doc-noteref"
          ><sup>18</sup></a> The currently dominant stock images of emergent tech cut us short of a nuanced, realistic,
          and
          equitable understanding and relating to these technologies. And furthermore, hinder us from meaningfully
          participating in the societal debate and claiming discursive agency to political action, for instance
          disagreement
          or refusal, with these technologies becoming part of daily life, often in hidden and misleading ways.</p>
        <p>Collectively, these circulating images contribute to the formation of a “socio-technical imaginary”, defined by
          Jasanoff and Kim as
          “collectively imagined forms of social life and social order reﬂected in the design and fulfilment of [the
          originator’s] . . . technological projects.’‘.<a
            href="#fn19"
            class="footnote-ref"
            id="fnref19"
            role="doc-noteref"
          ><sup>19</sup></a> In this case, the originators represent the agents involved in the images’ production and
          spread: art directors, editors, or other actors that secure their final destinations within public media. It
          goes
          without saying that the originators also extend to pushes from corporate and governmental actors seeking to
          boost
          the incorporation of new technologies into our society. The agents producing the imaginary play an active role
          in
          facilitating the”vanguard vision [becoming] communally adopted“.<a
            href="#fn20"
            class="footnote-ref"
            id="fnref20"
            role="doc-noteref"
          ><sup>20</sup></a> Socio-technical imaginaries are simultaneously also social imaginaries: they”[encode]
          collective visions of the good society [and its technologies]".<a
            href="#fn21"
            class="footnote-ref"
            id="fnref21"
            role="doc-noteref"
          ><sup>21</sup></a> Keeping this concept in view aids us to examine the channels through which the sci-fi
          techno-optimism of emergent tech imagery becomes public and common. Socio-technical imaginaries do not have the
          final dictum in how we see and relate to emergent tech: our ability to create new meanings with given tools
          remains ever strong. The all-around saturation of these sensationalised visions, however, inevitably guides the
          process over a range of pre-received and misleading hurdles.</p>
        <p>Given these processes of images gaining publicity, it is important to continually remind ourselves of the
          active
          role search engines' own politics play in determining what we see when searching,<a
            href="#fn22"
            class="footnote-ref"
            id="fnref22"
            role="doc-noteref"
          ><sup>22</sup></a> which weakens claims about these image results acting as clear mirrors or windows to our
          society.</p>
        <blockquote>Even within distorting mirrors, however, lies a reflection that can reveal a lot about
          ourselves.</blockquote>
        <p>We turn to search engines looking for information, facts, insights. To know more about a
          given topic, to clarify our position and understand it, to expand our horizons. When we turn to Google for
          visual
          explanations of emergent technologies, the engine stares back at us with hidden algorithmic inner logics. In the
          case of Google Images and emergent technologies such as AI, Bitcoin, Blockchain, it was important for me to ask
          what kind of information these images return to the searcher, and as mentioned before, what kind of
          relationships
          they construct with us as searchers and consumers of media.</p>

        <ol
          class="footnotes"
          start="8"
        >
          <li
            id="fn8"
            role="doc-footnote"
          >
            <p>Jan Van Toorn, ‘Design and Reflexivity’, 1994,
              https://designopendata.wordpress.com/portfolio/design-and-reflexivity-1994-jan-van-toorn/.<a
                href="#fnref8"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn9"
            role="doc-footnote"
          >
            <p>Vilém Flusser, <em>Towards a Philosophy of Photography</em> (London: Reaktion Books, 1983),
              15.<a
                href="#fnref9"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn10"
            role="doc-footnote"
          >
            <p>See for example: Thomas Streeter, ‘The Moment of Wired’, <em>Critical Inquiry</em> 31, no. 4
              (June 2005):
              755-79, https://doi.org/10.1086/444514.<a
                href="#fnref10"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn11"
            role="doc-footnote"
          >
            <p>Alberto Romele, ‘Images of Artificial Intelligence: A Blind Spot in AI Ethics’,
              <em>Philosophy &amp;
                Technology</em> 35, no. 1 (29 January 2022): 4,
              https://doi.org/10.1007/s13347-022-00498-3.<a
                href="#fnref11"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a>
            </p>
          </li>
          <li
            id="fn12"
            role="doc-footnote"
          >
            <p>Johannes Beus, ‘Why (Almost) Everything You Knew about Google CTR Is No Longer Valid’,
              SISTRIX, 14 July
              2020,
              https://www.sistrix.com/blog/why-almost-everything-you-knew-about-google-ctr-is-no-longer-valid/.<a
                href="#fnref12"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn13"
            role="doc-footnote"
          >
            <p>Beus.<a
                href="#fnref13"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn14"
            role="doc-footnote"
          >
            <p>Tarleton Gillespie, ‘The Relevance of Algorithms’, in <em>Media Technologies</em>, ed.
              Tarleton Gillespie,
              Pablo J. Boczkowski, and Kirsten A. Foot (The MIT Press, 2014), 183,
              https://doi.org/10.7551/mitpress/9780262525374.003.0009.<a
                href="#fnref14"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn15"
            role="doc-footnote"
          >
            <p>Christopher Grant Ward, ‘Stock Images, Filler Content and the Ambiguous Corporate Message’,
              <em>M/C
                Journal</em> 10, no. 5 (1 October 2007), https://doi.org/10.5204/mcj.2706.<a
                href="#fnref15"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a>
            </p>
          </li>
          <li
            id="fn16"
            role="doc-footnote"
          >
            <p>Giorgia Aiello, ‘Taking Stock’, Ethnography Matters, 28 April 2016,
              https://ethnographymatters.net/blog/2016/04/28/taking-stock/.<a
                href="#fnref16"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn17"
            role="doc-footnote"
          >
            <p>Ward, Stock Images and the Ambiguous corporate message.<a
                href="#fnref17"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn18"
            role="doc-footnote"
          >
            <p>Molly Wright Steenson, ‘A.I. Needs New Clichés’, Medium, 13 June 2018,
              https://medium.com/s/story/ai-needs-new-clich%C3%A9s-ed0d6adb8cbb.<a
                href="#fnref18"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn19"
            role="doc-footnote"
          >
            <p>Sheila Jasanoff and Sang-Hyun Kim, ‘Containing the Atom: Sociotechnical Imaginaries and
              Nuclear Power in
              the United States and South Korea’, <em>Minerva</em> 47, no. 2 (June 2009): 120,
              https://doi.org/10.1007/s11024-009-9124-4.<a
                href="#fnref19"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn20"
            role="doc-footnote"
          >
            <p>Sheila Jasanoff and Sang-Hyun Kim, eds., <em>Dreamscapes of Modernity: Sociotechnical
                Imaginaries and the
                Fabrication of Power</em> (Chicago, IL: University of Chicago Press), 4, accessed 26 June
              2022,
              https://press.uchicago.edu/ucp/books/book/chicago/D/bo20836025.html.<a
                href="#fnref20"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn21"
            role="doc-footnote"
          >
            <p>Jasanoff and Kim, 123.<a
                href="#fnref21"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn22"
            role="doc-footnote"
          >
            <p>Rogers, <em>Doing Digital Methods</em>.<a
                href="#fnref22"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>
      </section>
      <section>
        <!-- SECTION -->
        <h3
          id="approach-and-methodology"
          class="byHeading"
        >APPROACH AND METHODOLOGY</h3>
        <h2
          id="charting-the-waters"
          ref="heading"
        >Charting the Waters</h2>

        <p><strong>My research began with scraping image search engine results, focusing on Google Images, the world’s
            most
            commonly used image search engine.</strong><a
            href="#fn23"
            class="footnote-ref"
            id="fnref23"
            role="doc-noteref"
          ><sup>23</sup></a> The first step of the research was the query design, which was decided mutually between SETUP
          and I in the context of the broader research project, aiming to explore and characterise a broad range of
          keywords
          relating to emergent technologies and technological concepts. <strong>I explored the top results for the
            following
            keywords: <em>Artificial Intelligence, kunstmatige intelligentie, bitcoin, cloud computing, hacker, privacy,
              robot,</em> and <em>technology</em>.</strong> The images quickly began to tell a rather blue story, in the
          most literal sense of the word. Endowed with the dataset, I decided to continue my research practically and
          develop and design an <router-link
            class="page-link"
            to="/explorer"
          >explorer interface</router-link>. The interface can be used by researchers seeking to explore the Google search
          results
          further, with myself as the first client, and opens up the possibility for diving into the dataset in a wide
          manner of ways. You can read the <router-link
            to="/manual"
            class="page-link"
          >manual</router-link> to learn more about the dataset explorer interface and
          its diverse use cases.</p>
        <p><strong>For the scraping process, I wrote a custom NodeJS application, which searches Google from a graphical
            internet browser interface</strong><a
            href="#fn24"
            class="footnote-ref"
            id="fnref24"
            role="doc-noteref"
          ><sup>24</sup></a> <strong>from a location in The Netherlands (fixed through a VPN) and downloads a specified
            number of top image results and their metadata for one or more chosen keywords.</strong> Within the 33-day
          research period between July 20 and August 23, 2022, I set out to download the top 100 daily images for a range
          of
          keywords, in order to broadly sample the top results. <strong>The goal was to scan the digital image ecosystem
            for
            a broad range of concepts relating to technology, locating, and situating the queries in the Dutch
            context.</strong> Due to technical problems and interruptions, some days have partially or fully been redacted
          from the final dataset, totalling to 29 dates. <strong>The total number of individual images obtained was 2.900
            per category, for a total of 23.200 images, together with their corresponding database entries, indexing other
            accompanying information.</strong></p>
        <p>As a site of research, Google Images indexes and thematically curates a vast number of images that feature next
          to headlines in the media. The relevant data I could readily extract from the Google Image search results page
          included the <strong>ranking position</strong> (smaller number equals higher on the results page),
          <strong>website title</strong> (most often equivalent to the news article title), <strong>link to the
            website</strong> where the image appears, and <strong>the image file</strong> from the search result thumbnail
          in JPG format. With this bouquet of data, I could gain an overview of how the Google Image(s) economy for a
          given
          keyword, from a given search location, across time, a useful thing given that online archival tools such as the
          Wayback Machine do not preserve Google result pages, and the company itself does not provide a way to see how
          the
          search results looked on a given past date. With the images laid out in front of me, I decided to perform some
          first steps of working with the data and the explorer. <strong>My main concern was exploring the images as a
            collectivity rather than individual artefacts, scanning them for recurring themes and tropes.</strong> In some
          cases, I took the images out of the interface and conducted manual thematic analysis, by sorting and grouping
          the
          images into perceived themes and motifs (Figure 2). In others, I could get a sense of what’s happening by the
          sheer appearance of the results; <strong>
            much like minor variations on a familiar theme, unmistakably cut from the same
            cloth and woven from the same tired pop-cultural imagination
          </strong>.
        </p>

        <ol
          class="footnotes"
          start="23"
        >
          <li
            id="fn23"
            role="doc-footnote"
          >
            <p>Johannes Beus, ‘Why (Almost) Everything You Knew about Google CTR Is No Longer Valid’,
              SISTRIX, 14 July
              2020,
              https://www.sistrix.com/blog/why-almost-everything-you-knew-about-google-ctr-is-no-longer-valid/.<a
                href="#fnref23"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn24"
            role="doc-footnote"
          >
            <p>A Chromium instance, running from Google-issued scraping library Puppeteer.<a
                href="#fnref24"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>
      </section>
      <section>

        <!-- SECTION -->
        <h3
          id="findings"
          class="byHeading"
        >FINDINGS</h3>
        <h2 id="diving-into-the-deep">Diving into the Deep</h2>

        <p>As outlined until now, my main goal has been to shore up a sea of images that I could read for recurrent
          tropes,
          motives, and visual vocabularies that persist across the dataset of images of emergent technologies. <strong>My
            main ambition was to look for the ways in which the Deep Blue Sublime manifests itself, while keeping an eye
            out
            for other potential findings along the way.</strong> In doing so, I wanted to keep returning to my interest in
          how these images could <strong>
            enhance and sharpen a working definition of the Deep Blue Sublime, while the concept
            could
            mutually shape up and assist us in understanding the images
          </strong>, and while charting in rough some contours and
          outlines of the digital image economy through which these images tirelessly circulate.</p>
        <h3 id="defining-the-the-deep-blue-sublime">Defining the The Deep Blue Sublime</h3>
        <p>Most of the image results in this research belong to a universe governed by blue monochrome. This palette has
          come to be associated with much of emergent digital technology and reverberates throughout the mediasphere.
          While
          the images indeed mostly inhabit a deep, blue-coloured universe, the <em class="extra-emphasis">deep blue
            sublime</em> pick out more than
          the blue visuality. <strong>
            The concept seeks to capture the epistemic effects and relationships that these images
            produce
            in us as viewers, consumers, readers, and subjects of media
          </strong>.</p>
        <p>In my previous work, I proposed the term as a way to think about the knowledge relationship these images have
          with us, the ways in which they address us. That is to say, what kind of knowledge they produce when we
          encounter
          them. The concept stands on a rich history of sublimes, standing close to the technological sublime,<a
            href="#fn25"
            class="footnote-ref"
            id="fnref25"
            role="doc-noteref"
          ><sup>25</sup></a> algorithmic sublime,<a
            href="#fn26"
            class="footnote-ref"
            id="fnref26"
            role="doc-noteref"
          ><sup>26</sup></a> computational sublime,<a
            href="#fn27"
            class="footnote-ref"
            id="fnref27"
            role="doc-noteref"
          ><sup>27</sup></a> and the digital sublime<a
            href="#fn28"
            class="footnote-ref"
            id="fnref28"
            role="doc-noteref"
          ><sup>28</sup></a>. A defining feature of the technological sublime is “… a feeling of ‘astonishment, awe,
          terror,
          and psychic distance’— <strong>feelings once reserved for natural wonders or intense spiritual experiences, but
            increasingly applied to technologies that are new and potentially transformatory, but also complex and poorly
            understood</strong>.”<a
            href="#fn29"
            class="footnote-ref"
            id="fnref29"
            role="doc-noteref"
          ><sup>29</sup></a> The <em class="extra-emphasis">deep blue sublime</em> is both an epistemic orientation and
          aesthetic category, a
          manner of encountering, relating to, and knowing an image in which the above mentioned affective responses
          displace learning, and offset rational deliberation to secondary importance. These images appeal to and animate
          our beliefs, fears, and hopes. How they perform this work is far from participatory or reciprocal. By virtue of
          being stock images that dwell in public-facing media infrastructures, these images replicate and spread the
          optics and
          biases of a select few on behalf of the many. Furthermore, deep blue sublime images are wrought with inner
          tension: while they literally construct and symbolically build up the technologies they are employed to
          represent often deflect
          attention away from real-life discussions, applications, and concerns regarding the technology in question.
          <strong>They
            act as smokescreens - making us feel things, and look in astonishment one way, while the real technology work
            is
            taking place elsewhere.</strong><a
            href="#fn30"
            class="footnote-ref"
            id="fnref30"
            role="doc-noteref"
          ><sup>30</sup></a>
        </p>

        <ol
          class="footnotes"
          start="25"
        >
          <li
            id="fn25"
            role="doc-footnote"
          >
            <p>Jos de Mul, ‘The Technological Sublime’, Next Nature Network, 17 July 2011,
              https://nextnature.net/2011/07/the-technological-sublime; Kyle Craft-Jenkins, ‘Artificial
              Intelligence and
              the Technological Sublime: How Virtual Characters Influence the Landscape of Modern Sublimity’
              (Kentucky,
              University of Kentucky, 2012).<a
                href="#fnref25"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn26"
            role="doc-footnote"
          >
            <p>Morgan G Ames, ‘Deconstructing the Algorithmic Sublime’, <em>Big Data &amp; Society</em> 5,
              no. 1 (June
              2018): 4, https://doi.org/10.1177/2053951718779194.<a
                href="#fnref26"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn27"
            role="doc-footnote"
          >
            <p>Jon McCormack and Alan Dorin, ‘Art, Emergence, and the Computational Sublime’, in
              <em>Proceedings of The
                Second International Conference on Generative Systems in the Electronic Arts</em>
              (International
              Conference on Generative Systems in the Electronic Arts 2001, Monash University Publishing,
              2001), 67-81,
              https://research.monash.edu/en/publications/art-emergence-and-the-computational-sublime.<a
                href="#fnref27"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a>
            </p>
          </li>
          <li
            id="fn28"
            role="doc-footnote"
          >
            <p>Vincent Mosco, <em>The Digital Sublime: Myth, Power, and Cyberspace</em>, 1. paperback ed
              (Cambridge, Mass.
              London: MIT Pr, 2005).<a
                href="#fnref28"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn29"
            role="doc-footnote"
          >
            <p>Ames, ‘Deconstructing the Algorithmic Sublime’, 2.<a
                href="#fnref29"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn30"
            role="doc-footnote"
          >
            <p>Vrabič Dežman, <em>Encountering the Deep Blue Sublime: Artificial Intelligence in the Media
                Imaginary</em>,
              31.<a
                href="#fnref30"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>

        <!-- SECTION -->
        <section
          class="deep-blue-sublime"
          ref="deepblue1"
        >
          <h4 id="properties-of-the-deep-blue-sublime">(Some) Properties of the Deep Blue Sublime</h4>
          <ul>
            <li>
              <p>It can be difficult to explain, and easy to see and feel.</p>
            </li>
            <li>
              <p>Thrives in vagueness and withers under the light of specific or technical queries. A search for “AI” is
                highly sublime, “Neural Network” much less so.</p>
            </li>
            <li>
              <p>Puts first feelings of awe, wonder, fear, uneasiness, sublimity, while deferring rational deliberation
                and
                investigation</p>
            </li>
            <li>
              <p>Advances the narrative of digital technology as a frictionless, weightless, and imminent force</p>
            </li>
            <li>
              <p>Conceals the fact that emergent technologies are made up of “material resources, human labor, and
                data”,<a
                  href="#fn31"
                  class="footnote-ref"
                  id="fnref31"
                  role="doc-noteref"
                ><sup>31</sup></a> and subsequently renders opaque the “the human, social and environmental
                impacts”<a
                  href="#fn32"
                  class="footnote-ref"
                  id="fnref32"
                  role="doc-noteref"
                ><sup>32</sup></a> of said technologies.</p>
            </li>
            <li>
              <p>Shows emergent tech as impenetrable, unknowable, and beyond reach to the general public<a
                  href="#fn33"
                  class="footnote-ref"
                  id="fnref33"
                  role="doc-noteref"
                ><sup>33</sup></a></p>
            </li>
          </ul>
        </section>

        <ol
          class="footnotes"
          start="31"
        >
          <li
            id="fn31"
            role="doc-footnote"
          >
            <p>Kate Crawford and Vladan Joler, ‘Anatomy of an AI System: The Amazon Echo As An Anatomical
              Map of Human
              Labor, Data and Planetary Resources’, 2018, https://anatomyof.ai/.<a
                href="#fnref31"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn32"
            role="doc-footnote"
          >
            <p>‘Better Images of AI’, accessed 27 March 2022, https://betterimagesofai.org.<a
                href="#fnref32"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn33"
            role="doc-footnote"
          >
            <p>‘Better Images of AI’.<a
                href="#fnref33"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>
      </section>
      <section>
        <!-- SECTION -->
        <h2 id="looking-at-the-keywords">Looking at the keywords</h2>
        <p>An important part of my research was looking at images and assembling a vocabulary of motifs, both specific and
          those that persist across the board. What follows is a situated coding, subjective to my own calculus, that is
          by
          no means conclusive or exhaustive, and is prone to change over time. The purpose is to illuminate some pathways
          and trajectories in which pop-culture tropes and clichés have found their way into the visual vocabulary, and
          have
          become woven into the fabric of images used to represent emergent tech. By looking at the keywords, I could
          clearly see some visual qualities of <em class="extra-emphasis">deep blue sublime</em> imagery that crept in
          across most categories.
          Judging qualitatively, I could quickly recognise visual tropes such as <strong>binary rain</strong>,<a
            href="#fn34"
            class="footnote-ref"
            id="fnref34"
            role="doc-noteref"
          ><sup>34</sup></a> <strong>ethereal circuitry, expansive blue universes, sci-fi floating
            interfaces, point cloud oceans,</strong> and <strong>polygonal structures</strong>.</p>
        <h3 id="artificial-intelligence-kunstmatige-intelligentie">Artificial Intelligence / <br />Kunstmatige
          Intelligentie
        </h3>
        <ImageView currentKeyword="artificial-intelligence" />
        <p>My curiosity was first sparked by Artificial Intelligence when I was Googling the term and found myself
          confronted with the sublime wall of impossibly luminescent, trite-yet-sublime, images. Looking at the scraped
          dataset, I could soon begin to discern patterns, and decided to (quite literally) take all the unique images
          from
          the dataset back to the drawing board, and spatially organise them according to a vocabulary of motifs I
          recognised in their imagined lifeworld (Figure 2).</p>
        <figure>
          <a
            href="/files/image2.png"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/media/image2.png')"
              alt="Figure 2."
            />
          </a>
          <figcaption aria-hidden="true"><span class="figlabel">Figure 2.</span> Visual Mapping of the tropes present in
            images of AI.</figcaption>
        </figure>
        <p>Looking at all the images sorted, connections become clear between the different types of figurations present
          in
          the images and the cultural cornerstones and origins from which their depicted motifs hail. Amongst the many
          different associations and metaphors, <strong>the touch</strong> stood out. I could quickly notice the
          visual resemblance to <em>The Creation of Adam</em>, Michelangelo's grand oeuvre in the Sistine Chapel.
          The
          motif crops up in numerous variations, <strong>
            in which the relationship between Human and AI runs the gamut from
            friendship, neutrality, love, companionship, to aggression, hate, feuds, and mutual destruction
          </strong>. Undergirding
          these variations is the images’ construction of a duality between humanity and an omnipresent, and omniscient
          “higher being”, clearly according with the religious and transcendental AI imagery, which has been noticed and
          critically evaluated.<a
            href="#fn35"
            class="footnote-ref"
            id="fnref35"
            role="doc-noteref"
          ><sup>35</sup></a> In addition to religious iconography, a prolific tendency to depict the <strong>robot
            brain</strong> as stand-in for the notion of artificial intelligence is hardly new. The trope is one of
          many historical attempts by which to relate technology <em>qua</em> AI to the human brain.<a
            href="#fn36"
            class="footnote-ref"
            id="fnref36"
            role="doc-noteref"
          ><sup>36</sup></a> Common to nearly all the categories is an anthropomorphisation of artificial intelligence,
          which finds numerous shapes and expressions, ranging from photorealistic to pure sci-fi-dream-matter.</p>
        <h3 id="bitcoin">Bitcoin</h3>
        <ImageView currentKeyword="bitcoin" />
        <p>The images used to represent “bitcoin” mainly show photorealistic <strong>golden coins</strong>,
          sometimes
          combined with abstract visuals featuring financial graphics and indexes. In contemporary cryptocurrency
          debates,
          Bitcoin has been framed as a fundamental and revolutionary breaking away from the so-called “gold standard”
          that
          is associated with historical institutional hegemony and centralisation of economic power. The gilded imagery
          suggests just the opposite. The images serve to exemplify that the <em class="extra-emphasis">deep blue
            sublime</em> is not
          necessarily
          blue - rather, it is inherently misleading, as it constructs a false conceptual framework for understanding a
          technology (similar with Cloud Computing). As metaphor and visual learning aid, it leans a ladder against the
          wrong wall. Bitcoin’s visual metaphor aids our understanding only to the extent that we readily compare
          Bitcoin to
          fiat currency.</p>
        <h3 id="cloud-computing">Cloud Computing</h3>
        <ImageView currentKeyword="cloud-computing" />
        <p>Blue clouds replace physical data centres in these images. As an analogy, perhaps <em>The Cloud</em> once
          served
          its purpose and was perhaps even a useful mental model. In the contemporary status quo, where cloud
          computing has
          been applied and sold to consumers <em>en masse</em>, we can quickly see how the metaphor has become
          outgrown,
          much like an old sweater that one continues to empathically hold on to. Once our minds are primed with an
          initial
          mental model, it can take significantly more energy to update it, particularly if the revision differs
          drastically
          from the one learned initially. In that sense, <em class="extra-emphasis">deep blue sublime</em> images actively
          stand in our way
          of
          gaining a grounded understanding of emergent technologies, and how we refer to them, vis-à-vis their
          material and very political realities. In the case of emergent tech, stock imagery almost never depicts the
          technology as a material, infrastructural, resource-based operation, tied and traceable to a specific
          geographic
          place and time.</p>
        <h3 id="hacker">Hacker</h3>
        <ImageView currentKeyword="hacker" />
        <p><strong>Hooded figures</strong>, slouched across a computer in a dark environment, dominate this
          search
          query. Almost without exception, these individuals are cast as prototypically male. This category is
          misleading,
          and highly sexist, in lockstep with discriminatory historical trends of the masculinisation of expert and
          political computing culture,<a
            href="#fn37"
            class="footnote-ref"
            id="fnref37"
            role="doc-noteref"
          ><sup>37</sup></a> at the cost of the erasure of other social groups, notably women,<a
            href="#fn38"
            class="footnote-ref"
            id="fnref38"
            role="doc-noteref"
          ><sup>38</sup></a> from their participation in politically consequential and potentially subversive means
          of
          engaging in computer culture. The obscure visuality also ties into ideas of secretism, conspiracism, masks and
          unmasking, all related to conceptions and theoretisations about power.</p>
        <h3 id="privacy">Privacy</h3>
        <ImageView currentKeyword="privacy" />
        <p>The <strong>lock and key</strong> dominate this category, pointing towards a defensive approach
          towards
          privacy, an act of shielding from external hazards. The visualisations largely fail to address and
          disclose the
          internal workings of the tech privacy in question, such as protecting data from careless or risky
          management by
          the owner themselves.</p>
        <h3 id="robot">Robot</h3>
        <ImageView currentKeyword="robot" />
        <p>An interesting thing to note with depictions of robots is the prominent reference to the prototypical
          <strong>anthropomorphic</strong> <strong>whiteness</strong> of these images,<a
            href="#fn39"
            class="footnote-ref"
            id="fnref39"
            role="doc-noteref"
          ><sup>39</sup></a> combined with a sleek <strong>shininess</strong>, further abstracting away from, and
          concealing
          their
          material-based inner workings.<a
            href="#fn40"
            class="footnote-ref"
            id="fnref40"
            role="doc-noteref"
          ><sup>40</sup></a>
        </p>
        <h3 id="technology">Technology</h3>
        <ImageView currentKeyword="technology" />
        <p>The types of visuals I observed in the results for “technology” stood out as the most diverse out
          of the set of
          keywords studied. “Technology” conjures a bricolage of images belonging to the other keywords, such
          as Robot or
          AI, in addition to offering highly abstracted, infinitely sprawling, blue variations on tropes such
          as
          <strong>binary rain</strong> and <strong>ethereal circuitry</strong>, from which the
          substrate of
          the <em class="extra-emphasis">deep blue sublime</em> as an aesthetic category is woven. Importantly, these
          results speak
          to the
          impossibility of how stock images work in an epistemic sense, by pigeonholing and compressing a
          broad category in
          the world, into clearly labelled visual categories. <strong>How can the meaning of something as broad as
            technology
            be compressed and passed through the frame of a singular image?</strong>
        </p>

        <ol
          class="footnotes"
          start="34"
        >
          <li
            id="fn34"
            role="doc-footnote"
          >
            <p>Derived directly from the Matrix.<a
                href="#fnref34"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn35"
            role="doc-footnote"
          >
            <p>Singler, ‘The AI Creation Meme’.<a
                href="#fnref35"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn36"
            role="doc-footnote"
          >
            <p>Matthew Cobb, ‘Why Your Brain Is Not a Computer’, <em>The Guardian</em>, 27 February 2020,
              sec. Science,
              https://www.theguardian.com/science/2020/feb/27/why-your-brain-is-not-a-computer-neuroscience-neural-networks-consciousness;
              David Watson, ‘The Rhetoric and Reality of Anthropomorphism in Artificial Intelligence’,
              <em>Minds and
                Machines</em> 29, no. 3 (September 2019): 417-40,
              https://doi.org/10.1007/s11023-019-09506-6.<a
                href="#fnref36"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a>
            </p>
          </li>
          <li
            id="fn37"
            role="doc-footnote"
          >
            <p>Nathan Ensmenger, ‘“Beards, Sandals, and Other Signs of Rugged Individualism”: Masculine
              Culture within the
              Computing Professions’, <em>Osiris</em> 30, no. 1 (January 2015): 38-65,
              https://doi.org/10.1086/682955.<a
                href="#fnref37"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn38"
            role="doc-footnote"
          >
            <p>Mar Hicks, <em>Programmed Inequality: How Britain Discarded Women Technologists and Lost Its
                Edge in
                Computing</em>, History of Computing (Cambridge, MA: MIT Press, 2017); Janet Abbate,
              <em>Recoding Gender:
                Women’s Changing Participation in Computing</em>, History of Computing (Cambridge, Mass.:
              MIT Press,
              2012).<a
                href="#fnref38"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a>
            </p>
          </li>
          <li
            id="fn39"
            role="doc-footnote"
          >
            <p>Cave and Dihal, ‘The Whiteness of AI’; Mhlambi, ‘God in the Image of White Men’; Thao Phan,
              ‘Amazon Echo
              and the Aesthetics of Whiteness’, <em>Catalyst: Feminism, Theory, Technoscience</em> 5, no. 1
              (1 April
              2019): 1-38, https://doi.org/10.28968/cftt.v5i1.29586.<a
                href="#fnref39"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn40"
            role="doc-footnote"
          >
            <p>Crawford and Joler, ‘Anatomy of an AI System’.<a
                href="#fnref40"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>
      </section>
      <section>
        <!-- SECTION -->
        <h3
          id="reflection-and-limitations"
          class="byHeading"
        >REFLECTION AND LIMITATIONS</h3>
        <h2
          id="making-sense-of-the-results"
          ref="heading"
        >Making sense of the results</h2>

        <blockquote>
          <p>After seeing and treading the deep blue sublime, what to do?</p>
        </blockquote>
        <p><strong>While it seems tempting to point towards the images largely as somehow deterministic
            reflections of our
            societal beliefs and values, it is important to remember that the images embody the socio-cultural
            norms and
            values of their makers, stock image producers, and of their consumers, art directors and other
            decision-making
            agents within the media.</strong> This is further complicated by the monopolistic structure of the
          image
          economy, and the discriminatory nature of search engine image results, resulting in the predominant
          encoding of a
          white, male, optics into their visual world.<a
            href="#fn41"
            class="footnote-ref"
            id="fnref41"
            role="doc-noteref"
          ><sup>41</sup></a> The processes and mechanisms through which these images enter our media distances
          us from
          democratic potentials of image-making and imagining together, and invites discussion regarding the
          implications of
          such a political economy.</p>
        <p>The infinitely scrolling and algorithmically valorised feed of image results “freezes” all past
          results into an
          accumulating archive, which further sediments and reinforces the symbolic value and status of the
          already-seen,
          already-tired motifs and imaginaries.</p>
        <blockquote>It becomes harder and harder to escape the visual
          style that has become
          locked in, fossilised, and solidified over-time.</blockquote>
        <p>Certainly, Google is not the final
          arbiter, and cliché
          stock images producers coexist with other image makers. <strong>Sampling water downstream does not necessarily
            lead to a
            clear directory of the multiple upstream tributaries that make up the flow, but it can help gauge the elements
            involved. Similarly, studying stock images of emergent tech can guide us to a complex interplay of factors and
            actors.</strong> One such factor is the tendency of
          Google’s
          algorithm to make certain images iconic, i. e. to keep trending results on top over time - this
          tendency is
          evident when exploring the dataset through the <router-link
            to="/explorer"
            class="page-link"
          >explorer interface</router-link>. Another is the
          tendency for
          already-established mental models to “stick around”, irrespective of their accuracy or
          verisimilitude (e.g. golden
          Bitcoin, ). A third could simply be the law of supply-demand: If stock images continue to be
          purchased by media
          outlets that push often-equally-sensationalised emergent tech stories, stock image producers are
          incentivised to
          respond to the demand and create more of the same.</p>
        <p>Taking a “birds-eye” view of the images allows us to consider them as a collectivity - as a
          coordinated ensemble,
          signifying into our world. <strong>Looking at Google Image results at a given point in time, from a
            given
            location, could be seen as a way of “checking the temperature” in the mediasphere</strong>.
          Exploring the
          symbolic visions that enjoy heavy rotation in the media, and circulate through public discussions
          and publicity,
          can be a valuable part of a broader project to gauge where we’re at in terms of their social
          integration and
          understanding.</p>

        <ol
          class="footnotes"
          start="41"
        >
          <li
            id="fn41"
            role="doc-footnote"
          >
            <p>Giorgia Aiello, ‘Taking Stock’; Paul Frosh, <em>The Image Factory: Consumer Culture,
                Photography and the
                Visual Content Industry</em>, 1. publ, New Technologies/New Cultures Series (Oxford: Berg,
              2003); Noble,
              Safiya Umoja. <em>Algorithms of Oppression: How Search Engines Reinforce Racism</em>. New
              York: New York
              University Press, 2018.<a
                href="#fnref41"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>
      </section>
      <section>
        <!-- SECTION -->
        <h3
          id="future-urgencies"
          class="byHeading"
        >FUTURE URGENCIES</h3>
        <h2 id="where-will-we-go-with-these-images">Where will we go with these images?</h2>

        <p>From golden bitcoins to floating immaterial clouds to shiny white humanoid robots, this research
          has considered a
          range of metaphors that don’t serve us any longer. Much like the technologies themselves, the images
          used to
          mediate them to the public are “products of specific political and historical contexts, with inbuilt
          biases,
          opacity, partiality and illegibility and have the potential to enhance discrimination and
          domination”.<a
            href="#fn42"
            class="footnote-ref"
            id="fnref42"
            role="doc-noteref"
          ><sup>42</sup></a> <strong>In the case of stock images, it is clear that very few people prime and
            furnish the
            collective imaginary on behalf of very many. Once envisioned as sci-fi visions, futuristic
            promotional material,
            as mental shortcuts to a shared vision of innovative technology, the images gathered in this
            research are past
            their expiration date</strong>. <strong>We’ve exhausted our current metaphors, and they’re also
            exhausting
            us.</strong> That is not to say that the old metaphors are fixed nor final - new cultural tropes
          emerge over
          time, joining, supplanting, or cross-pollinating across categories (e.g. <strong>binary
            rain</strong>
          appearing in nearly all of the keywords studied).</p>
        <p>In this research, I’ve attempted to underline the public importance and relevance of these images,
          and some
          problematic aspects that make them stick around. The practical consequences of a public visual world
          defined by
          these images range far and wide. The responsibility lies and lives between all agents of the image
          lifecycle
          - from their birth mostly on stock image platforms, to their use by art directors and editors of
          media outlets and
          publications, to their preferred ranking on Google due to implicit algorithmic imperatives, to the
          technological
          originators and stakeholders who are served by riding the “hype wave” of these exalted imaginaries.
          Studying
          public search engine image results opens up doors: not necessarily to an accurate reflection of
          people’s social
          practices and search habits, but rather shines a ray of light that bounces, refracts, and shines on
          a busy digital
          media image economy, made up mostly of stock images on the producing end, and media platforms on the
          other end as
          the consumers and circulators of these deep blue images.</p>
        <p>It’s time for a renegotiation of the visual language we use to represent emergent technologies, and
          of the
          monopolistic processes through which these images become a matter of the public good. There are no
          simplistic
          interventions into this complex network of comorbidities. <strong>This concerns not only
            image-makers as
            individuals, but also involves a general sensitisation, and involvement from all involved actors
            and
            stakeholders of the emergent-tech image economy.</strong>. A greater sensitisation towards the
          <em class="extra-emphasis">deep blue
            sublime</em> facade is a small aspirational step. Where we are is not serving us, there is no
          clear path towards
          alternatives, other than keeping the sublime facade at bay, while populating the gap with knowledge,
          alternative
          figurations, and envisioning possible paths of redemption from the status quo.
        </p>
        <blockquote>
          Gaining a
          critical
          awareness of looking, media and visual literacy, is a necessary step towards noticing and possibly
          imagining
          different scenarios going forward.
        </blockquote>
        <p>The search for better images is an ongoing, iterative, and open shared process, which we have to
          continually
          revisit as we renew our relationship with these technologies. Public imagery might benefit from more
          collective
          forms and formats of image-making. <strong>At the core of our current problem is the fact that these
            images do not
            enable (and arguably outright prevent) meaningful conversation with a larger audience, that
            concerns the
            consequences of these technologies, their status in the world, and our seemingly inextricable
            future with
            them.</strong> They also deflect from questions or investigations of accountability and
          structurations of power
          and conceal the real impacts these technologies have as material infrastructures. Since my initial
          research,
          others too have noticed the pernicious trends in emergent tech imagery, and several initiatives have
          been launched
          to address the sameness and problematic aspects of stock images. One such initiative is BBC-backed
          Better Images
          of AI, launched in 2021 to “create a new repository of better images of AI that anyone can use,
          starting with a
          collection of inspirational images.”<a
            href="#fn43"
            class="footnote-ref"
            id="fnref43"
            role="doc-noteref"
          ><sup>43</sup></a> The creation of alternative public repositories can be a valuable step towards
          defining a set
          of criteria for guiding future images of technology.</p>
        <p>As stock images and news headlines are increasingly becoming AI-generated,<a
            href="#fn44"
            class="footnote-ref"
            id="fnref44"
            role="doc-noteref"
          ><sup>44</sup></a> it is equally interesting to observe the same clichéd and tired visuals emerging from
          image-synthesis AI systems, such as OpenAI’s (widely popular and publicised) DALL·E 2.<a
            href="#fn45"
            class="footnote-ref"
            id="fnref45"
            role="doc-noteref"
          ><sup>45</sup></a> These systems are supposed to propose future stock imagery for use within public
          media, while
          being trained on old and problematic stock image inputs. It is easy to imagine the same misleading
          visual language
          being rehashed again, and again, and again. The urgency to briefly pause and reconsider where we’re
          going calls on
          us ever more pressingly.</p>
        <figure>
          <img
            :src="require('@/assets/images/media/image3.png')"
            alt="A picture containing different, light, colors Description automatically generated"
          />
          <figcaption aria-hidden="true"><span class="figlabel">Figure 3.</span> DALL·E 2 image outputs for the query
            “Artificial
            Intelligence”. Same
            thing, packaged
            differently?
          </figcaption>
        </figure>

        <ol
          class="footnotes"
          start="42"
        >
          <li
            id="fn42"
            role="doc-footnote"
          >
            <p>Matthew Fuller and Eyal Weizman, <em>Investigative Aesthetics: Conflicts and Commons in the
                Politics of
                Truth</em> (Verso Books, 2021), 16.<a
                href="#fnref42"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn43"
            role="doc-footnote"
          >
            <p>‘Better Images of AI’.<a
                href="#fnref43"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn44"
            role="doc-footnote"
          >
            <p>Benj Edwards, ‘Adobe Stock Begins Selling AI-Generated Artwork’, <em>Ars Technica</em>, 6
              December 2022,
              https://arstechnica.com/information-technology/2022/12/adobe-stock-begins-selling-ai-generated-artwork/;
              ‘AI
              Generated Images: The Next Big Thing in Stock Media’, 13 April 2022,
              https://www.stockphotosecrets.com/stock-agency-insights/ai-generated-images.html.<a
                href="#fnref44"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn45"
            role="doc-footnote"
          >
            <p>OpenAI. ‘DALL·E 2’. OpenAI. Accessed 9 February 2022. https://openai.com/dall-e-2/.<a
                href="#fnref45"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>
      </section>
      <section>
        <!-- SECTION -->
        <h2 id="conclusion">Conclusion</h2>

        <p>In this research, I took a deep dive into Google Images, and images of emergent technology, with an
          additional
          aim to flesh out and develop a working definition for the <em class="extra-emphasis">deep blue sublime</em>, in
          order to
          promote a
          greater socio-technical awareness of the fact that there is absolutely nothing natural or intuitive
          about the
          images used to represent digital technology. They are heavily constructed, staged, and at best
          aspirational media
          artefacts. The importance lies in not overlooking, but rather looking at
          (mostly)
          stock images<a
            href="#fn46"
            class="footnote-ref"
            id="fnref46"
            role="doc-noteref"
          ><sup>46</sup></a> used to represent digital technologies and exploring their livelihood as the
          essence of the
          digital image economy and the quilting points of the visual world of digital media. <strong>Taking
            these images
            seriously, questioning them and holding conversations with them as lively conversation partners,
            can help us
            further our understanding of ourselves.</strong></p>
        <p>This research takes intermediate steps in a larger project. It is my hope and wish that
          researchers, image-makers
          and others interested in researching this issue find use in the exploratory interface and
          preliminary dataset.
          Mapping out the visual motifs and tropes that inhabit this image economy could be an interesting
          next research
          step, while the interface and dataset can enable researchers of all kinds to engage in semantic or
          visual analysis
          of the images (for more, see the <router-link
            to="/manual"
            class="page-link"
          >Manual</router-link>). The data in this research spans
          approximately one
          month. Save for potential limitations to scraping, the methodology allows for any duration of
          longitudinal
          tracking, and it would be very interesting to index and track the life of emergent tech imagery over
          a much longer
          timeframe.</p>
        <p>Future research could fruitfully explore all the keywords in detail, to create a vocabulary of all
          the different
          tropes and motifs that appear, perhaps assembling a library similar to that of <a
            href="https://tvtropes.org/"
            class="outside-link"
            target="_blank"
          >TV
            Tropes</a>.<a
            href="#fn47"
            class="footnote-ref"
            id="fnref47"
            role="doc-noteref"
          ><sup>47</sup></a> It would be interesting to ask: Who’s hiding behind the deep blue veneers and
          façades? Who does
          it serve to have emergent technologies dressed in the <em class="extra-emphasis">deep blue sublime</em>? <em>Cui
            bono?</em>
          How does the
          online stock image economy work? The wider question, of course, remains about images. Where do they speak
          from, and who
          do they address? And what kind of visual worlds, and approaches towards public-image-making, might
          serve us
          better?</p>

        <ol
          class="footnotes"
          start="46"
        >
          <li
            id="fn46"
            role="doc-footnote"
          >
            <p>Giorgia Aiello, ‘Taking Stock’.<a
                href="#fnref46"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
          <li
            id="fn47"
            role="doc-footnote"
          >
            <p>https://tvtropes.org/<a
                href="#fnref47"
                class="footnote-back"
                role="doc-backlink"
              >↩︎</a></p>
          </li>
        </ol>
      </section>
      <section
        class="deep-blue-sublime"
        ref="deepblue2"
      >
        <h2 id="awareness-checklist">Deep Blue Sublime Awareness Checklist
        </h2>
        <ul>
          <li>
            <p>Is the image depicting a situation that’s fantastical, futuristic, bigger-than-life?</p>
          </li>
          <li>
            <p>Does the image appeal to feelings of awe-, amazement, grandeur?</p>
          </li>
          <li>
            <p>
              Is the image a photograph or descriptive depiction offering evidence of real-world instances of the
              technology?
            </p>
          </li>
          <li>
            <p>Are the creators or operators of the technology named?</p>
          </li>
          <li>
            <p>Is the image made in-house by the media company or publisher, or outsourced?</p>
          </li>
          <li>
            <p>Is the image used a generic stock image?</p>
          </li>
          <li>
            <p>Does the image depict a situation that could be described as sci-fi future? </p>
          </li>
          <li>
            <p>
              Does the image show anthropomorphised representations of computing, e.g. human brains, robotic hands, etc.?
            </p>
          </li>
          <li>
            <p>
              Have you seen elements in this image before in the context of popular culture (films/games/books)? What do
              you recognise from pop culture?
            </p>
          </li>
        </ul>
      </section>
      <!-- <section role="doc-endnotes">
        <ol>
          <li>Endnote 1</li>
        </ol>
      </section> -->

      <p class="thank-you">🌌 <br />Thank you for reading!</p>
    </article>
  </div>
</template>
<script>
// import { defineComponent, shallowRef } from 'vue'
// import { marked } from 'marked';
// import md from '../assets/article_v2.md';
import ImageView from './ImageView.vue';
// import Markdown from 'vue3-markdown-it';
// import MarkdownItFootnote from "markdown-it-footnote";

// FROM: https://github.com/hmsk/frontmatter-markdown-loader/issues/238

// const component = defineComponent({
//   name: 'MarkdownComponent',
//   template: `<ImageView currentKeyword="artificial-intelligence" /><p>${nameName}</p>`,
//   // We can allow custom components in Markdown like so...
//   components: { Markdown, ImageView },
//   data() {
//     return {
//       nameName: "LogRocket",
//     }
//   },
// })

export default {
  name: 'EssayComponent',
  components: {
    ImageView,
  },
  data() {
    return {
      // component: shallowRef(component),
      markdown: '',
      source: "# Hello World",
      scrollParameters: {
        heading: '',
        scrolledRatio: 0,
      },
    };
  },
  methods: {
    handleScroll() {
      try {
        const children = this.$refs.article.querySelectorAll('h2');
        let currentScrollDistance = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
        let WindowHeight = window.innerHeight

        const dblue = this.$refs.deepblue1;
        const dbluetwo = this.$refs.deepblue2;

        // Controls the dark background
        if (((dblue.getBoundingClientRect().top < WindowHeight - 200) && (dblue.getBoundingClientRect().top > ((dblue.offsetHeight * -1) + 200))) || ((dbluetwo.getBoundingClientRect().top < WindowHeight - 200) && (dbluetwo.getBoundingClientRect().top > ((dbluetwo.offsetHeight * -1) + 200)))) {
          // console.log('In View'); /* DEBUG */
          // Add class to body
          document.body.classList.add('dark-background');
        } else {
          document.body.classList.remove('dark-background');
        }

        // Header text effect
        if (currentScrollDistance % 5 === 0) {
          const header = this.$refs.header;
          let string = 'Defining the Deep Blue Sublime'
          header.innerHTML = string.split(' ').map(e => `<span class='span-${Math.ceil(Math.random() * 3)}'>${e}</span>`).join(' ');
        }

        children.forEach(h => {
          // Current scroll line at bottom of screen
          // let currentBottomScroll = currentScrollDistance + WindowHeight

          // Height of parent container section
          let parentHeight = h.parentElement.offsetHeight;

          // Vertical pos of element start from top of document
          // let elTopStart = h.getBoundingClientRect().top
          let elTopStart = currentScrollDistance + h.getBoundingClientRect().top;
          // start of element at bottom of viewpoer
          let elBottomViewportStart = elTopStart - WindowHeight;

          // Vertical position of element end from top of document
          let elBottomEnd = elTopStart + parentHeight;

          if (currentScrollDistance > (elBottomViewportStart) && currentScrollDistance < elBottomEnd) {
            let percentageScrolledThrough = (currentScrollDistance - elBottomViewportStart) / parentHeight;

            if (percentageScrolledThrough > 1) percentageScrolledThrough = 0;

            this.scrollParameters.heading = h.innerHTML
            this.scrollParameters.ratio = percentageScrolledThrough.toFixed(2);

            // Send it to the parent APP component
            this.$emit("comp-data", this.scrollParameters);

            // console.log(`Top Start: ${elBottomViewportStart}, Bottom End: ${elBottomEnd}, Current Scroll Pos: ${currentScrollDistance} ,Parent Height: ${parentHeight}}, Percentage scrolled through ${percentageScrolledThrough}`);
          }

          // let elementTopScrollDistance = scrollDistance + WindowHeight - topDistance
          // let scrolledRatio = elementTopScrollDistance
          // let scrolledRatio = scrollDistance - topDistance + parentHeight
          // ellTopStart = 
          // elBottomEnd = 

          // if (topDistance < WindowHeight) {
          //   // Change to match current heading
          //   this.currentHeading = h.innerHTML
          //   // Move this outside so it always knows...
          //   this.scrolledRatio = topDistance
          //   this.$emit('name', h.innerHTML);
          // }
        })
      } catch (error) {
        // console.log('Error'); /* DEBUG */
      }
    },
    handleGalleryScroll() {
      const imageContainers = document.querySelectorAll('.carousel-wrapper');

      imageContainers.forEach(c => {
        const cScrollWidth = c.scrollWidth;
        this.scrollInterval = window.setInterval(() => {
          if (c.scrollLeft !== cScrollWidth) {
            c.scrollTo(c.scrollLeft + 1, 0);
          } else {
            c.scrollTo(0, 0);
          }
        }, 50)
      })
    }
  },
  computed: {
    getBackgroundGradient() {
      return `radial-gradient(circle closest-corner at ${Math.floor(Math.random() * 101)}% 40%, var(--blue--main), blue 60%, rgb(29, 0, 145) 70%, rgb(7, 7, 78))`
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('load', this.handleGalleryScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('load', this.handleGalleryScroll);
    window.clearInterval(this.scrollInterval);
    document.body.classList.remove('dark-background');

  }
}

</script>
<style scoped lang="scss">
/* This style goes to the child image gallery component */

.carousel-wrapper {
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.article-wrapper {
  font-family: var(--primary-font);
  margin-bottom: 8rem;
}

u {
  text-underline-offset: 5px;
}

.essay-header {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  user-select: none;

  h1 {
    width: 100%;
    line-height: 1.5;
    font-size: 6rem;
    max-width: 60%;
    text-align: center;
    line-height: 0.8;
    margin-bottom: 2rem;
    font-family: 'Redaction 35';
    font-style: bold;
  }

  :deep(.span-1) {
    font-family: 'Redaction 50';
    // font-style: bold;
  }

  :deep(.span-2) {
    font-family: 'Redaction 100';
    // font-style: bold;
  }
}

.article-wrapper {
  .table-of-contents {
    font-family: var(--secondary-font);
    padding: calc(var(--flow-space) * 2);
    background-color: var(--blue--main);
    color: var(--clr-background);

    transition: 0.2s padding ease-in-out;

    h2 {
      font-family: var(--display-font);
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      padding: 0;
    }

    a {
      display: block;
      padding: 0;
      color: var(--clr-background);

      &:hover {
        background-color: var(--clr-background);
        color: var(--blue--main);
      }

      &:not(:last-of-type) li {
        border-bottom: none;
      }
    }

    li {
      padding: 1.5rem;
      font-size: 1.5rem;
      border: 2px solid var(--clr-background);
    }

    p {
      margin: 0;
    }

    p:nth-child(2) {
      margin-top: calc(var(--flow-space)*0.5);
      font-size: 1rem;
      font-family: var(--accent-font);
      text-transform: uppercase;
    }

    span {
      font-size: 1rem;
    }
  }
}

article p,
article h2,
article h3,
article h4,
article h5,
article ul,
article ol,
article figcaption,
article blockquote,
.deep-blue-sublime {
  max-width: 40rem;
  margin: var(--flow-space) auto;
  line-height: 1.5;
  scroll-margin-top: 100px;
}

article figcaption,
article blockquote,
.deep-blue-sublime,
article ul,
article ol {
  padding: 0.5rem;
}

article>.carousel-wrapper {
  padding-left: 0;
  padding-right: 0;
}

article figure {
  margin: auto;
}

article img {
  margin: 4rem auto 3rem auto;
  max-height: 80vh;
  max-width: 90vw;
  box-shadow:
    /* inner white */
    /* inner right cyan broad */
    0 0 50px #fff,
    /* outer white */
    -10px 0 40px rgba(2, 29, 119, 0.41),
    /* outer left magenta */
    10px 0 40px rgba(0, 30, 255, 0.505);
  /* outer right cyan */
  border-radius: 1rem;
}

.no-border {
  box-shadow: none;
}

article figcaption {
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 0.9rem;
  line-height: 1.2;
  color: var(--blue--main);
}

.figlabel {
  font-family: var(--accent-font);
  text-transform: uppercase;
}

.extra-emphasis {
  font-family: var(--display-font);
  font-size: 1.08em;
  color: var(--blue--main);
}

article {
  h2 {
    font-family: var(--display-font);
    line-height: 1;
    position: relative;
  }

  h3 {
    font-family: var(--secondary-font);
    margin-bottom: 2rem;
    margin-top: 4rem;
    line-height: 1.1;
  }
}

.article-wrapper h3.byHeading {
  font-family: var(--accent-font);
  font-size: 0.9rem;
  color: var(--blue--main);
  font-weight: 400;
  margin-bottom: 0rem;
  text-align: right;
  position: absolute;
  left: 50%;
  margin-top: 0;
  transform: translateX(-37rem);
  width: 15rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid blue;


  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: blue;
    position: absolute;
    right: -1rem;
    transform: translateX(100%);
    filter: blur(5px);
  }
}

.article-wrapper .byHeading+h2 {
  margin-bottom: 3rem;
  position: relative;
}

article ul {
  list-style-type: circle;
  padding-left: 2rem;
}

article blockquote {
  padding: 1rem;
  width: 100%;
  position: relative;
  font-size: 1.5em;
  border-left: 2px solid var(--blue--main);
  margin-top: var(--flow-space);
  line-height: 1.35;

  font-family: var(--secondary-font);

  p {
    padding: 0;
    margin: 0;
  }
}

.page-link {
  background-color: var(--blue--main);
  color: var(--clr-background);
  font-family: var(--primary-font);
  padding: 0.2em 0.5em 0.2em 0.5em;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 0.9em;
  position: relative;
  bottom: 0.1em;
  text-transform: uppercase;

  &:hover {
    background-color: var(--grey--main-fade);
    color: var(--blue--main);
  }
}

.outside-link {
  border-bottom: 1px solid;
  padding-bottom: 1px;
  font-family: var(--primary-font);
  letter-spacing: 0.03em;

  &:hover {
    background-color: var(--blue--main);
    color: var(--clr-background);
  }
}

strong {
  // font-family: var(--accent-font);
  /* font-size: 0.85em; */
  color: var(--blue--main);
  background-color: var(--grey--main-fade);
  // font-weight: 500;
}

em {
  font-style: italic;
}

.backdrop-top {
  z-index: -100;
  display: block;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
}

.article-wrapper h2 {
  text-align: center;
  font-size: 3rem;
  margin-top: 6rem;
}

.article-wrapper h3 {
  margin-top: 4rem;
  font-size: 1.5rem;
  font-family: var(--accent-font);
  text-transform: uppercase;
}

.article-wrapper h4 {
  font-size: 1.2rem;
  color: var(--blue--main);
  font-family: var(--secondary-font);
  font-weight: 600;
}

article ol {
  list-style: decimal;
  padding-left: 2rem;

  li {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
}

sup {
  position: relative;
  bottom: 0.3rem;
  font-family: var(--accent-font);
  font-weight: 500;
  font-size: 0.7rem;
  margin-right: 0.2rem;
  margin-left: 0.1rem;
}

.footnote-ref sup:hover {
  background-color: var(--blue--main);
  color: var(--clr-background);
}

.footnotes {
  background-color: var(--grey--main-fade);
  padding: 2rem;
  padding-left: 2.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: calc(var(--flow-space)*2);
  box-sizing: border-box;
  // overflow: hidden;

  ol {
    padding-left: 1rem;
  }

  li {
    padding-left: 0.1rem;
  }

  li::marker {
    color: var(--blue--main);
  }

  li,
  p {
    font-size: var(--font-caption);
    white-space: pre-wrap;
    width: 100%;
  }

  .footnote-back {
    padding: 0.2rem;
    padding-bottom: 0;
    margin-left: 0.2em;

    &:hover {
      color: var(--clr-background);
      background-color: var(--blue--main);
    }
  }

}

.deep-blue-sublime {
  padding: 2rem;
  border: 1px solid var(--blue--main);
  margin: 4rem auto;

  ul {
    margin-top: 0;
  }

  h2 {
    margin: 2rem auto;
    margin-bottom: 1rem;
  }

  h4 {
    margin-bottom: 0;
  }

  p {
    padding-top: 0;
    padding-bottom: 0;
    margin: var(--flow-space) auto;
  }
}

.thank-you {
  text-align: center;
  font-size: 2rem;
  margin-top: 8rem;
  margin-bottom: 12rem;
  font-family: 'Redaction 35';
}

/* li::before {
  content: counter(li);
  color: red;
  display: inline-block;
  width: 1em;
  margin-left: -1em
}

li {
  counter-increment: li
} */

@media screen and (max-width: 992px) {

  article section>* {
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
  }

  html,
  body {
    overflow-x: hidden;
  }

  .essay-header h1 {
    font-size: 6rem;
    padding: 1rem;
    max-width: 100%;
  }

  .deep-blue-sublime {
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: calc(100% - 2rem);
  }

  article blockquote {
    padding: 1rem;
    margin-left: 1rem;
    width: calc(100% - 2rem);
  }

  .article-wrapper .table-of-contents {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .footnotes {
    p {
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}
</style>