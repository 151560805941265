<template>
  <article>
    <h1 id="about-page-possibilities-of-using-the-explorer-interfacedataset">Image Dataset Explorer</h1>
    <p>The Image Dataset Explorer is an interface to visualise the contents of the Google Images scrape. It is
      envisioned as
      an open-access resource for researchers and journalists seeking to understand the ways in which technologies are
      spoken about, both in a visual and verbal sense. The interface is designed as an entry point for multimodal
      analyses.
    </p>
    <p>
      The interface offers possibilities for detailed thematic, content, or discourse
      analysis of the images as a collectivity, or a deeper dive into their contents, and the immediate headlines, and
      furthermore retrieving the entire article accompanying the search results via the link provided.</p>
    <p>The explorer displays all the scrapes obtained by the Image Scraper, and orders them chronologically from left to
      right, and according to their ranking on Google Images, top-to-bottom (from 1st to 100th search result).</p>
    <p>Each search result indexes the following properties:</p>
    <table style="width:98%;">
      <colgroup>
        <col style="width: 10%" />
        <col style="width: 88%" />
      </colgroup>
      <tbody>
        <tr class="odd">
          <td style="text-align: left;">Position</td>
          <td style="text-align: left;">the ranking of the image in Google Image search.<br />
            1 = first top result</td>
        </tr>
        <tr class="even">
          <td style="text-align: left;">Image</td>
          <td style="text-align: left;">The image accompanying the search result. Typically the main image of the
            article or
            website.</td>
        </tr>
        <tr class="odd">
          <td style="text-align: left;">Link</td>
          <td style="text-align: left;">the link to the article/website from the Google Image search result</td>
        </tr>
        <tr class="even">
          <td style="text-align: left;">Title</td>
          <td style="text-align: left;">the webpage title, most often corresponding to the title of the Article.</td>
        </tr>
      </tbody>
    </table>

    <h2>Display Possibilities</h2>

    <div class="card-gallery-container">
      <div class="card-gallery">
        <div
          class="card"
          v-for="n in 13"
          :key="n"
        >
          <div class="img">
            <p>IMAGE</p>
          </div>
          <div class="link">
            <p>LINK</p>
          </div>
          <div class="title">
            <P>TITLE</P>
          </div>
        </div>
      </div>
    </div>

    <p>By default, all three properties are shown.</p>
    <h3 id="image-only-view">Image-only view</h3>
    <p>At small column sizes, the image-only view allows for a longitudinal comparison of the dataset.</p>
    <div class="callout">
      <h4 id="highlight-identical-artefact">VISUALISE IDENTICAL IMAGES</h4>
      <p>When this toggle is turned on, the tool finds all images within the dataset that match the current mouse-over
        image.
      </p>
    </div>

    <h3 id="link-only-view">Link-only view</h3>
    <p>This view gives insights into the top websites where the images appear, or travel to.</p>
    <div class="callout">
      <h4 id="show-domain-frequency-heatmap">VISUALISE DOMAIN FREQUENCY</h4>
      <div class="gradations">
        <p>LESS</p>
        <div class="step"></div>
        <div class="step"></div>
        <div class="step"></div>
        <div class="step"></div>
        <div class="step"></div>
        <div class="step"></div>
        <div class="step"></div>
        <div class="step"></div>
        <div class="step"></div>
        <div class="step"></div>
        <p>MORE</p>
      </div>
      <p>This toggle colorises links relative to their number of appearances in the dataset. Gives a quick overview
        into
        where the images live, and on which sites they appear in the search engine the most.</p>
    </div>

    <h3 id="link-title">LINK + TITLE</h3>
    <p>This view allows for cross-comparison between domains and the rhetorics. Gives access to the link of the article,
      and
      allows for further semantic analysis.</p>
    <h3 id="title-image">IMAGE + TITLE</h3>
    <p>This view enables the comparison of the page title and the image used on that page. For many data points in the
      dataset, an entrypoint into the cross-comparison of article titles and the cover images used within.</p>
  </article>
</template>
<script>

export default {
  name: 'ManualComponent',
  data() {
    return {
      markdown: "# Hello World",
    };
  },
  computed: {
  },
  async created() {
  }
}

</script>
<style scoped lang="scss">
/* This style goes to the child image gallery component */
.carousel-wrapper {
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

$width: 150px;

.card-gallery-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  -webkit-mask-image:
    -webkit-gradient(linear, left center, right center,
      color-stop(0, transparent),
      color-stop(.10, rgba(0, 0, 0, .5)),
      color-stop(.35, black),
      color-stop(.65, black),
      color-stop(.90, rgba(0, 0, 0, .5)),
      color-stop(1, transparent));
}

.card-gallery {
  display: grid;
  margin: 2rem auto;
  min-width: fit-content;
  grid-template-columns: repeat(13, 150px);
  column-gap: 0.5rem;
}

.card {
  pointer-events: none;
  user-select: none;
  width: $width;
  border: 1px dashed var(--grey--main);

  &:nth-child(7) {
    border: 2px solid var(--blue--main);

    p {
      opacity: 1;
      margin: 0;
      color: var(--blue--main);
      font-family: var(--accent-font);
      font-weight: 400;
    }

    .img {
      width: 100%;
      height: $width;
      padding: 0.5rem;
    }

    .link {
      width: 100%;
      border: 1px solid var(--blue--main);
      border-left: 0;
      border-right: 0;
      padding: 0.5rem;
    }

    .title {
      padding: 0.5rem;
    }
  }

  p {
    opacity: 0;
    margin: 0;
    color: var(--blue--main);
    font-family: var(--primary-font);
  }

  .img {
    width: 100%;
    height: $width;
    padding: 0.5rem;
  }

  .link {
    width: 100%;
    border: 1px dashed var(--grey--main);
    border-left: 0;
    border-right: 0;
    padding: 0.5rem;
  }

  .title {
    padding: 0.5rem;
  }

}

.article-wrapper {
  z-index: 1;
  position: relative;
  font-family: var(--primary-font);
  margin-bottom: 12rem;
}

article {
  font-size: 1.2rem;
  margin: 5rem auto;
  margin-top: 8rem;
  margin-bottom: 12rem;
}

u {
  text-underline-offset: 5px;
}

.essay-header {
  height: 70vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.callout {
  background-color: var(--grey--main-fade);
  padding: 1.7rem 1.5rem;

  p:last-child {
    margin-bottom: 0;
  }
}

article p,
article h2,
article h3,
article h4,
article h5,
article ul,
article ol,
article figcaption,
article table,
article div {
  max-width: 40rem;
  margin: auto;
  line-height: 1.2;
}

article table {
  border-collapse: collapse;
  margin: 2rem auto;
  font-size: 1rem;
}

table td {
  padding: 1rem;
  padding-left: 0.7rem;
  border: 1px solid var(--blue--main);
  color: var(--blue--main);
  border-left: none;
  border-right: none;
}

table td:nth-child(1) {
  width: 30%;
  font-family: var(--accent-font);
  text-transform: uppercase;
}

.myQuote {
  padding-left: 2em;
}

.gradations {
  min-width: 350px;
  max-width: 450px;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;

  p {
    font-family: var(--accent-font);
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--blue--main);
  }

  .step {
    width: 20px;
    height: 20px;
    // border: 1px solid var(--blue--main);

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) {
        background-color: rgba(12, 24, 189, $i * 0.1);
      }
    }
  }
}

.byHeading {
  color: var(--grey--main);
  margin-top: 4rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

article figure {
  margin: auto;
}

article img {
  margin: 4rem auto 3rem auto;
  max-height: 80vh;
  box-shadow:
    /* inner white */
    /* inner right cyan broad */
    0 0 50px #fff,
    /* outer white */
    -10px 0 40px rgba(2, 29, 119, 0.41),
    /* outer left magenta */
    10px 0 40px rgba(0, 30, 255, 0.505);
  /* outer right cyan */
  border-radius: 1rem;
}

.no-border {
  box-shadow: none;
}

article figcaption {
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 0.9rem;
  line-height: 1.2;
  color: var(--blue--main);
}

article p {
  margin: 1rem auto;
  line-height: 1.4;
  font-size: 1rem;
}

article ul {
  list-style-type: circle;
  padding-left: 2rem;
}

em,
strong {
  font-family: var(--primary-font);
  font-size: 0.85em;
  color: var(--blue--main);
}

h1 {
  text-align: center;
  font-size: 4rem;
  line-height: 1;
  color: var(--blue--main);
  font-family: var(--display-font);
  max-width: 40rem;
  margin: auto;
  margin-bottom: 3rem;
}

.backdrop-top {
  z-index: -100;
  display: block;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  background: linear-gradient(black, var(--blue--main), white);
}

article h2 {
  margin-top: 3rem;
  text-align: left;
  font-size: 1.5rem;
  color: var(--blue--main);
  font-family: var(--secondary-font);
}

article h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: var(--blue--main);
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-family: var(--accent-font);
}

article h4 {
  font-size: 1rem;
  color: var(--blue--main);
  margin-bottom: 1rem;
}

article ol {
  list-style: decimal;
  padding-left: 2rem;
}

sup {
  position: relative;
  bottom: 0.3rem;
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 0.7rem;
  margin-right: 0.2rem;
  margin-left: 0.1rem;
}

.deep-blue-sublime {
  padding: 2rem;
  border: 1px solid var(--blue--main);
  margin: 4rem auto;
  width: 40rem;
}

.deep-blue-sublime h4 {
  margin-bottom: 2rem;
}

.page-link {
  background-color: var(--blue--main);
  color: var(--clr-background);
  font-family: var(--primary-font);
  padding: 0.2em 0.5em 0.3em 0.5em;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 0.9em;
  position: relative;
  bottom: 0.1em;

  &:hover {
    background-color: var(--grey--main-fade);
    color: var(--blue--main);
  }
}

/* li::before {
  content: counter(li);
  color: red;
  display: inline-block;
  width: 1em;
  margin-left: -1em
}

li {
  counter-increment: li
} */
</style>
