<template>
  <header class="topHeader">
    <router-link
      v-if="!screenMobile.value"
      to="/explorer"
    >
      <button>Dataset Explorer</button>
    </router-link>
    <!-- <button v-if="this.$route.path === '/' && screenMobile.value">Visit this page on Desktop to Explore the Dataset
    </button> -->
    <router-link
      v-if="!screenMobile.value"
      to="/manual"
    >
      <button>
        Manual
      </button>
    </router-link>
    <router-link to="/">
      <button>
        Research Paper
      </button>
    </router-link>
    <router-link to="/about">
      <button>
        About
      </button>
    </router-link>
    <a
      href="#toc"
      v-if="this.$route.path === '/'"
    >
      <button
        @mouseover="elementHover = true"
        @mouseout="elementHover = false"
        class="toTop"
        :class="{ outside: !scrolled }"
        @click="scrollToTop"
      >
        <p :style="{ backgroundImage: !(scrollParameters.heading === 'Table of Contents') ? getBgGradient : '' }">
          {{ elementHover ? 'Go to Table of Contents' : scrollParameters?.heading }}
        </p>
      </button>
    </a>
    <button
      v-else
      class="toTop"
      :class="{ outside: !scrolled }"
      @click="scrollToTop"
    >TO TOP</button>
  </header>

  <router-view
    v-slot="{ Component }"
    @comp-data="getName"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>

export default {
  name: "App",
  title: 'Defining the Deep Blue Sublime',
  data() {
    return {
      scrolled: false,
      elementHover: false,
      scrollParameters: { heading: 'TOC', ratio: 0, darkBackground: false },
    }
  },
  components: {
  },
  computed: {
    getBgGradient() {
      return `linear-gradient(
    to right, 
    blue,
    blue ${this.scrollParameters.ratio * 100 - 3}%,
    white ${this.scrollParameters.ratio * 100 + 3}%,
    white
  )`
    },
    year() {
      const newd = new Date;
      return newd.getFullYear();
    }
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 200;
    },
    scrollToTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    getName(value) {
      // This event works with the data from the EssayComponent
      this.scrollParameters = value;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<!-- Add REF to get the name of the nearest header item -->

<style lang="scss">
@font-face {
  font-family: 'Roboto';
  src: url('@/assets/style/fonts/Roboto-Regular.woff2') format('woff2'),
    url('@/assets/style/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('@/assets/style/fonts/SpaceGrotesk-SemiBold.woff2') format('woff2'),
    url('@/assets/style/fonts/SpaceGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('@/assets/style/fonts/SpaceGrotesk-Light.woff2') format('woff2'),
    url('@/assets/style/fonts/SpaceGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('@/assets/style/fonts/SpaceGrotesk-Bold.woff2') format('woff2'),
    url('@/assets/style/fonts/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('@/assets/style/fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
    url('@/assets/style/fonts/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('@/assets/style/fonts/SpaceGrotesk-Medium.woff2') format('woff2'),
    url('@/assets/style/fonts/SpaceGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url('@/assets/style/fonts/IBMPlexSerif-Regular.woff2') format('woff2'),
    url('@/assets/style/fonts/IBMPlexSerif-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url('@/assets/style/fonts/IBMPlexSerif-Italic.woff2') format('woff2'),
    url('@/assets/style/fonts/IBMPlexSerif-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url('@/assets/style/fonts/IBMPlexSerif-Bold.woff2') format('woff2'),
    url('@/assets/style/fonts/IBMPlexSerif-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Redaction 70';
  src: url('@/assets/style/fonts/Redaction70-Regular.woff2') format('woff2'),
    url('@/assets/style/fonts/Redaction70-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Redaction 70';
  src: url('@/assets/style/fonts/Redaction70-Italic.woff2') format('woff2'),
    url('@/assets/style/fonts/Redaction70-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Redaction 100';
  src: url('@/assets/style/fonts/Redaction100-Regular.woff2') format('woff2'),
    url('@/assets/style/fonts/Redaction100-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Redaction 50';
  src: url('@/assets/style/fonts/Redaction50-Regular.woff2') format('woff2'),
    url('@/assets/style/fonts/Redaction50-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Redaction 35';
  src: url('@/assets/style/fonts/Redaction35-Regular.woff2') format('woff2'),
    url('@/assets/style/fonts/Redaction35-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


:root {
  --primary-font: 'Roboto';
  --secondary-font: 'IBM Plex Serif';
  --display-font: 'Redaction 70';
  --accent-font: 'Space Grotesk';
  --font-caption: 0.8rem;
  --font-body: 1rem;
  --font-large: 1.5rem;
  --font-xlarge: 2rem;
  --blue--main: #0c18bd;
  --grey--main: rgb(200, 200, 200);
  --grey--main-fade: rgba(188, 190, 229, 0.2);
  --clr-background: white;
  --flow-space: 1rem;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  cursor: crosshair;
}

.topHeader .toTop {
  position: fixed;
  z-index: 1000;
  bottom: var(--flow-space);
  left: var(--flow-space);
  transition: 0.4s transform ease-in-out, background-color 0.4s ease-in-out, color 0.4s ease-in-out, height 0.4s ease-in-out, width 0.4s ease-in-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: var(--grey--main);
  background-color: var(--clr-background);

  .dark-background & {
    color: var(--clr-background);
    background-color: var(--grey--main-fade);
  }

  &:hover {
    background-color: var(--blue--main);
    background-image: var(--blue--main) !important;
    background: var(--blue--main);

    p {
      color: var(--clr-background);
      background: none !important;
      -webkit-background-clip: inherit;
      -moz-background-clip: inherit;
      -webkit-text-fill-color: inherit;
      -moz-text-fill-color: inherit;
    }
  }

  p {
    font-family: var(--primary-font);
    background-size: 100%;
    background-color: var(--blue--main);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: rgba(84, 84, 84, 0.266);
    -moz-text-fill-color: rgba(84, 84, 84, 0.266);
  }
}

.toTop:hover {
  cursor: n-resize;
}

.toTop.outside {
  transform: translateY(300%);
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  font-family: var(--primary-font);
  transition: 0.4s background-color ease-in-out;
  overscroll-behavior-y: none;
  background-color: var(--clr-background);

  ::selection {
    color: white;
    background-color: var(--blue--main);
  }
}

body.dark-background {
  background-color: var(--blue--main);

  // TODO: finalise the button styling
  button {
    user-select: none;
  }

  .toTop {
    background-image: white !important;
    color: white;
  }

  a {
    color: var(--clr-background);
  }

  ::selection {
    color: var(--blue--main);
    background-color: var(--clr-background);
  }

  .article-wrapper {

    p,
    h2,
    h3,
    h4,
    ol,
    li,
    em,
    strong,
    sup {
      color: white;
    }

    a {
      color: white;

      &:hover,
      &.page-link {
        background-color: white;
        color: var(--blue--main);
      }
    }

    .footnote-ref {

      &:hover sup {
        color: var(--blue--main);
      }

      sup:hover {
        background-color: var(--clr-background);
        color: var(--blue--main);
      }
    }

    .deep-blue-sublime {
      border-color: white;
    }

    .footnotes {
      background-color: var(--blue--main);

      li::marker {
        color: var(--clr-background);
      }
    }
  }
}

a {
  text-decoration: none;
}

a:visited {
  color: var(--blue--main);
}

.topHeader {

  a {
    display: block;
  }
}

.topHeader .router-link-active button {
  background-color: var(--blue--main);
  color: var(--clr-background);

  .dark-background & {
    background-color: var(--grey--main-fade);
  }
}

.topHeader button {
  background-color: var(--clr-background);
  color: var(--blue--main);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  margin-left: 0.3rem;
}

h1,
h2,
h3,
h4 {
  font-family: var(--primary-font);
  font-weight: 400;
}

h1 {
  font-size: var(--font-xlarge);
}

h2 {
  font-size: var(--font-large);
}

p {
  font-family: var(--secondary-font), serif;
  font-weight: 300;
}

.topHeader {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: min-content;
  line-height: 0.5;
  padding-top: var(--flow-space);
  padding-right: var(--flow-space);
  margin-bottom: 1.5rem;
}

.topHeader a:nth-child(1) button {
  /* top-left | top-right | bottom-right | bottom-left */
  // border-radius: 20px 0 0 20px;
  margin-left: 0;
  margin-right: 0.07rem;

}

.topHeader a:nth-child(2) button {
  /* top-left | top-right | bottom-right | bottom-left */
  // border-radius: 0 20px 20px 0;
  margin-left: 0;
}

.topHeader span {
  font-size: 0.75em;
  opacity: 0.2;
}

button {
  white-space: nowrap;
  display: block;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: var(--primary-font);
}

#app {}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

footer {
  margin: auto;
  color: var(--blue--main);
  padding: 1rem;
  text-align: center;

  p {
    font-family: var(--primary-font);
  }
}

@media screen and (max-width: 992px) {
  html {
    font-size: 14px;
  }
}
</style>
