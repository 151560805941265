<template>
  <article>
    <h1 id="about">About the Project</h1>

    <p>Binary rain backdrops and white humanoid robots, suspended amidst sparkly interfaces in futuristic blue worlds.
      Science fiction clichés about emergent technology have flooded the mediasphere. In text, and certainly also in
      image. These <em class="extra-emphasis">Deep Blue Sublime</em> visions are not clear mirrors, but rather a
      hindrance to our social comprehension. Not only do they create unrealistic expectations of technology's potential,
      but they also foreclose our collective imagination of alternative futures with technology. Importantly, these images
      do not represent emergent tech – they constitute it. Technology cannot be reduced to a sci-fi movie. Nor is it an
      imminent “superpower” that simply happens to us; it cannot exist outside of ourselves. Why keep up appearances?
    </p>
    <p>It's time to move on from the existing image-making of technology. Together with designers, media makers, and
      research partner Fontys Hogeschool Journalistiek, SETUP researches how to develop an alternative visual language for
      tech. For more information, <a href="https://www.setup.nl/onderzoek/nieuwe-beeldtaal-voor-technologie/"><u>click
          here</u></a>. Within the research, deepbluesublime.tech represents a joint artistic research between SETUP and
      designer / media scholar Dominik Vrabič Dežman.
      The project and accompanying <router-link
        to="/"
        class="page-link"
      >research paper</router-link> explores the lives of emergent tech images in the
      contemporary online media ecosystem.</p>
    <blockquote>What are the most dominant images of technology on the web? Where do these come from, where do they end
      up, and whom do they reach? What do these images portray and convey? What kind of work do these images perform? What
      frame of interpretation do they open up, and what do they close off? And how do these shape, influence, and affect
      our collective imaginaries of emergent tech?</blockquote>
    <p>The dataset, accessible through the <router-link
        to="/explorer"
        class="page-link"
      >explorer interface</router-link>, represents a first-ever snapshot in time (Summer 2022) of the visual
      representation of technology they appeared in top search results and major institutional and media platforms, and
      allows researchers to dive into further textual and image-based analysis of these images.</p>
    <h2 id="colofon">COLOPHON</h2>

    <table>
      <tr>
        <td>
          <h3>Commissioner</h3>
        </td>
        <td>SETUP</td>
      </tr>
      <tr>
        <td>
          <h3>Project coordination</h3>
        </td>
        <td>Marissa Memelink (SETUP)</td>
      </tr>
      <tr>
        <td>
          <h3>Concept &amp; research design</h3>
        </td>
        <td>Dominik Vrabič Dežman &amp; Marissa Memelink (SETUP)</td>
      </tr>
      <tr>
        <td>
          <h3>Research and Analysis</h3>
        </td>
        <td>Dominik Vrabič Dežman</td>
      </tr>
      <tr>
        <td>
          <h3>Web Design</h3>
        </td>
        <td>Dominik Vrabič Dežman</td>
      </tr>
      <tr>
        <td>
          <h3>Development</h3>
        </td>
        <td>Dominik Vrabič Dežman</td>
      </tr>
      <tr>
        <td>
          <h3>Thanks To</h3>
        </td>
        <td>Stimuleringsfonds Creatieve Industrie &amp; Gemeente Utrecht</td>
      </tr>
    </table>
    <h2 id="about-setup">About SETUP</h2>
    <p><a href="http://www.setup.nl">SETUP</a> is a Dutch media lab researching and redefining digital culture. Fuelled
      with a critical yet humorous
      signature and hands-on approach, SETUP translates complex themes rising from our technosociety into tangible ideas
      in collaboration with artists, designers, and makers. We aim to provide a broad audience with the tools necessary to
      design a brave new world and infuse it with human values and new-found creativity.</p>
    <h2 id="about-dominik-vrabič-dežman">About Dominik Vrabič Dežman</h2>
    <p><a href="https://www.dominik.ooo">Dominik Vrabič Dežman (SI)</a> is a media scholar and information designer, based
      in Amsterdam, NL. After studies in visual communication at Design Academy Eindhoven and the Royal Art Academy in The
      Hague, Dominik is a graduate student of Philosophy and Media Studies at the University of Amsterdam. His practice
      revolves around the study of images used to mediate emergent technologies the public, as
      their social presence ties into wider questions of social epistemology and ethics of image-making, examining the
      production of visibility, and the socio-technical imaginaries surrounding emergent technologies.
    </p>
  </article>
</template>
<script>

export default {
  name: 'ManualComponent',
  data() {
    return {
      markdown: "# Hello World",
    };
  },
  computed: {

  },
  async created() {
    document.body.classList.add('dark-background');
  },
  async beforeUnmount() {
    document.body.classList.remove('dark-background');
  }
}

</script>
<style scoped lang="scss">
/* This style goes to the child image gallery component */
.carousel-wrapper {
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

$width: 150px;

.article-wrapper {
  z-index: 1;
  position: relative;
  font-family: var(--primary-font);
  margin-bottom: 12rem;
}

article {
  font-size: 1.2rem;
  margin: 5rem auto;
  margin-top: 8rem;
  margin-bottom: 12rem;
  padding: 0 1rem;
}

u {
  text-underline-offset: 5px;
}

.essay-header {
  height: 70vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--clr-background);
}

.callout {
  background-color: var(--grey--main-fade);
  padding: 1.4rem 1rem;
  border-left: 2px solid var(--blue--main);

  p:last-child {
    margin-bottom: 0;
  }
}

.page-link {
  background-color: var(--grey--main-fade);
  color: var(--clr-background);
  font-family: var(--primary-font);
  padding: 0.2em 0.5em 0.2em 0.5em;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 0.9em;
  position: relative;
  bottom: 0.1em;
  text-transform: uppercase;
  border: none;

  &:hover {
    background-color: var(--grey--main-fade);
    color: var(--blue--main);
  }
}

.extra-emphasis {
  font-family: var(--display-font);
  font-size: 1.08em;
  font-style: italic;
}

article p,
article h2,
article h3,
article h4,
article h5,
article ul,
article ol,
article figcaption,
article table,
article div {
  max-width: 40rem;
  margin: auto;
  line-height: 1.2;
  color: var(--clr-background);
}

article table {
  border-collapse: collapse;
  margin: 2rem auto;
  font-size: 1rem;
}

table td {
  padding: 1rem;
  border: 2px solid var(--clr-background);
  border-left: none;
  border-right: none;
  color: var(--clr-background);

  h3 {
    margin: none;
    text-transform: uppercase;
  }
}

table td:nth-child(1) {
  width: 40%;
  padding-left: 0.7rem;
}

.myQuote {
  padding-left: 2em;
}

article blockquote {
  max-width: 40rem;
  margin: auto;
  padding: 1rem;
  width: 100%;
  position: relative;
  font-size: 1.3em;
  border-left: 2px solid var(--clr-background);
  margin-top: var(--flow-space);
  line-height: 1.35;
  color: var(--clr-background);

  font-family: var(--secondary-font);

  p {
    padding: 0;
    margin: 0;
  }
}

.byHeading {
  color: var(--grey--main);
  margin-top: 4rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

article figure {
  margin: auto;
}

article a {
  border-bottom: 1px solid white;

  &:hover {
    background-color: var(--grey--main-fade);
  }
}

article img {
  margin: 4rem auto 3rem auto;
  max-height: 80vh;
  box-shadow:
    /* inner var(--clr-background) */
    /* inner right cyan broad */
    0 0 50px #fff,
    /* outer var(--clr-background) */
    -10px 0 40px rgba(2, 29, 119, 0.41),
    /* outer left magenta */
    10px 0 40px rgba(0, 30, 255, 0.505);
  /* outer right cyan */
  border-radius: 1rem;
}

.no-border {
  box-shadow: none;
}

article p {
  margin: 1rem auto;
  line-height: 1.4;
  font-size: 1rem;
}

article ul {
  list-style-type: circle;
  padding-left: 2rem;
}

em,
strong {
  font-family: var(--primary-font);
  font-size: 0.85em;
  color: var(--clr-background);
}

h1 {
  text-align: center;
  font-size: 4rem;
  line-height: 1;
  color: var(--clr-background);
  font-family: var(--display-font);
  max-width: 40rem;
  margin: auto;
  margin-bottom: 3rem;
}

.backdrop-top {
  z-index: -100;
  display: block;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  background: linear-gradient(black, var(--blue--main), var(--clr-background));
}

article h2 {
  margin-top: 3rem;
  text-align: left;
  font-size: 1.5rem;
  color: var(--clr-background);
  font-family: var(--secondary-font);
}

article h3 {
  font-size: 1rem;
  color: var(--clr-background);
  margin-bottom: 1rem;
  font-family: var(--accent-font);
}

article h4 {
  font-size: 1.2rem;
  color: var(--clr-background);
}

article ol {
  list-style: decimal;
  padding-left: 2rem;
}

sup {
  position: relative;
  bottom: 0.3rem;
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 0.7rem;
  margin-right: 0.2rem;
  margin-left: 0.1rem;
}

.deep-blue-sublime {
  padding: 2rem;
  border: 1px solid var(--blue--main);
  margin: 4rem auto;
  width: 40rem;
}

.deep-blue-sublime h4 {
  margin-bottom: 2rem;
}


/* li::before {
  content: counter(li);
  color: red;
  display: inline-block;
  width: 1em;
  margin-left: -1em
}

li {
  counter-increment: li
} */
</style>
