<template>
  <section
    v-if="!this.screenMobile.value"
    class="image-explorer"
  >
    <div class="title-info">
      <div class="titleAndButton">
        <h1>Image Dataset Explorer</h1>
        <p>Version 1.0. Some things may be slow to load.</p>
      </div>
    </div>
    <aside class="header-bar">
      <div class="headerItem-wrapper">
        <h5>KEYWORD</h5>
        <select
          class="options-keyword"
          v-model="pickedKeyword"
          @change="onChange($event)"
        >
          <option
            v-for="item in keywords"
            :key="item"
            :id="item"
            :value="item.split(' ').join('-')"
          >{{ item }}</option>
        </select>
      </div>
      <div class="headerItem-wrapper plusMinus">
        <fieldset class="settingContainer">
          <div class="options-column_width">
            <button
              @click="rangeValue--"
              class="minus"
              v-tippy="{ content: 'Narrower' }"
              :class="{ greyedOut: rangeValue <= 5 }"
            >—</button>
            <button
              @click="rangeValue++"
              v-tippy="{ content: 'Wider' }"
              class="plus"
              :class="{ greyedOut: rangeValue >= 20 }"
            >+</button>
            <!-- <label for="myRange"><input
                name="myRange"
                type="range"
                min="4"
                max="30"
                class="slider"
                id="myRange"
                v-model="rangeValue"
              />
            </label> -->
          </div>
        </fieldset>
      </div>

      <!-- Input section (component) -->
      <div class="headerItem-wrapper">
        <h5>DISPLAY</h5>
        <!-- Toggle Button Style 1 -->
        <fieldset class="options-thingsToDisplay">
          <div
            v-for="option in ['images', 'links', 'titles']"
            :key="option"
          >
            <label
              class="toggler-wrapper style-1"
              :for="option"
            >
              <input
                type="checkbox"
                :id="option"
                :value="option"
                v-model="checkedNames"
              >
              <div class="toggler-slider">
                <div class="toggler-knob"></div>
              </div>
            </label>
            <p class="badge">{{ option }}</p>
          </div>
        </fieldset>
      </div>

      <div class="headerItem-wrapper">
        <h5>VISUALISE</h5>
        <fieldset class="options-highlight">
          <div
            v-tippy="{ content: 'Hovering over image highlights other identical images in the grid' }"
            :class="{ greyedOut: !checkedNames.includes('images') || frequency === true }"
          >
            <label
              class="toggler-wrapper style-1"
              for="highlight"
            >
              <input
                type="checkbox"
                id="highlight"
                value="highlight"
                v-model="compHighlight"
              >
              <div class="toggler-slider">
                <div class="toggler-knob"></div>
              </div>
            </label>
            <p class="badge">Identical Images</p>
          </div>
          <div
            v-tippy="{ content: 'Darker = More Frequent' }"
            :class="{ greyedOut: !checkedNames.includes('links') }"
          >
            <label
              class="toggler-wrapper style-1"
              for="frequency"
            >
              <input
                type="checkbox"
                id="frequency"
                value="frequency"
                v-model="compFrequency"
              >
              <div class="toggler-slider">
                <div class="toggler-knob"></div>
              </div>
            </label>
            <p class="badge">Domains</p>
          </div>
          <!-- TODO: Fix the images highlighting not working -->
          <!-- <label
            for="highlight"
            :class="{ greyedOut: !checkedNames.includes('images') }"
          ><input
              type="checkbox"
              id="highlight"
              value="highlight"
              v-model="highlight"
              :class="{ greyedOut: !checkedNames.includes('images') }"
            />Highlight Identical Images on Hover</label><br /> -->
          <!-- <label
            for="frequency"
            :class="{ greyedOut: !checkedNames.includes('links') || checkedNames.includes('images') }"
          ><input
              type="checkbox"
              id="frequency"
              value="frequency"
              v-model="frequency"
              :class="{ greyedOut: !checkedNames.includes('links') || checkedNames.includes('images') }"
            />Highlight Domains by Frequency</label> -->
        </fieldset>
      </div>
      <div
        class="headerItem-wrapper downloadFile"
        v-tippy="{ content: 'By downloading the dataset, you consent to using it exclusively for non-commercial research purposes.' }"
        @mouseover="mouseOverDownload = true"
        @mouseout="mouseOverDownload = false"
      >
        <div v-if="!mouseOverDownload">
          <h5>DOWNLOAD DATASET (277 MB, .zip)</h5>
        </div>
        <a
          v-else
          href="/files/dataset.zip"
          download="dataset.zip"
        >
          <div>
            <h5>AGREE AND DOWNLOAD</h5>
          </div>
        </a>
      </div>
    </aside>

    <transition mode="out-in">
      <div v-if="loading">
        <p class="loading-notice">RESULTS LOADING...</p>
      </div>
      <div
        v-else
        class="tableContainer"
        :class="{ highlighting: highlight }"
        ref="tableContainer"
      >
        <table>
          <thead>
            <tr>
              <th
                scope="col"
                class="cornerPoint"
                v-tippy="{ content: 'Return to Beginning' }"
                @click="this.$refs.tableContainer.scrollTo({ top: 0, left: 0 });"
              >
                <img
                  :src="require('@/assets/arrow.svg')"
                  alt=""
                >
              </th>
              <th
                scope="col"
                v-for="item in postList['1']"
                :key="item['scrape date']"
              >
                <h4>{{ formatDate(item['scrape date']) }}</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(entry, key) in postList"
              :key="key"
            >
              <th
                scope="row"
                v-tippy="{ content: `${key}. position in search results`, placement: 'right' }"
              >
                <h4> {{ key }}
                </h4>
              </th>
              <td
                v-for="(entry, index) in postList[key]"
                :key="index"
                @mouseover="this.currentIndex = index"
              >
                <div
                  class="imageWrapper"
                  ref="imgWrapper"
                  v-if="!loading && entry.title !== 'missing'"
                  :style="{ width: `${rangeValue}rem` }"
                >
                  <!-- <transition>
                    <p
                      class="positionOnPage"
                      v-if="checkedNames.includes('positions')"
                      :class="{ inLine: !checkedNames.includes('images') }"
                    >
                      <span>{{ entry.positionOnPage }}</span>
                    </p>
                  </transition> -->

                  <div class="img-inner-wrapper">
                    <img
                      v-if="!loading && checkedNames.includes('images')"
                      class="resultImage"
                      @error="this.missing = true"
                      :src="!loading ? getUrl(entry.filesize) : 'require(`@/assets/blank.png`)'"
                      @mouseover="currentImageId = entry.filesize"
                      :style="{ height: `${rangeValue}rem`, width: `${rangeValue}rem`, 'transition-delay': !highlight ? Math.random(0, 1) * 0.5 + 's' : '' }"
                      :class="{ selected: currentImageId === entry.filesize, faded: frequency, missing: missing }"
                    />
                  </div>
                  <a
                    class="linkToPage"
                    target="_blank"
                    :class="{ heatmap: frequency }"
                    v-tippy="{
                      content: frequency ? `This domain appeared in the dataset ${statistics[entry.link?.match(
                        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im
                      )[1]]} times.` : ''
                    }"
                    :href="entry.link"
                    :style="{
                      '--frequency': frequency && statistics[entry.link?.match(
                        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im
                      )[1]],
                      '--max-frequency': Object.values(statistics)[0]
                    }"
                    v-if="checkedNames.includes('links')"
                  >
                    {{
                      entry.link?.match(
                        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
                      )[1]
                    }}
                  </a>
                  <p
                    class="title"
                    v-if="checkedNames.includes('titles')"
                  >
                    {{ entry.title }}
                  </p>

                </div>
                <div
                  class="imageWrapper blank"
                  v-else
                >
                  <p>DATA MISSING</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>

  </section>
  <section
    class="desktop-notice"
    v-else
  >
    <p class="desktop-notice__p">Head over to desktop to explore the dataset.</p>
  </section>
</template>

<script>
// Combine all of these into one big JSON, and then create the display based on that...
// Import all keywords;

import axios from 'axios';

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

import aiJson from "../../src/assets/google/artificial-intelligence/data-table.json";
// import bitcoinJson from "../../src/assets/google/bitcoin/data-table.json";
// import cloudJson from "../../src/assets/google/cloud-computing/data-table.json";
// import kiJson from "../../src/assets/google/kunstmatige-intelligentie/data-table.json";
// import hackerJson from "../../src/assets/google/hacker/data-table.json";
// import privacyJson from "../../src/assets/google/privacy/data-table.json";
// import technologyJson from "../../src/assets/google/technology/data-table.json";
// import robotJson from "../../src/assets/google/robot/data-table.json";
import statisticsJson from "../../src/assets/statistics.json";

export default {
  data() {
    return {
      postList: aiJson,
      checkedNames: ["images", "links", "titles"],
      keywords: [
        "artificial intelligence",
        "bitcoin",
        "cloud computing",
        "kunstmatige intelligentie",
        "hacker",
        "privacy",
        "technology",
        "robot",
      ],
      highlight: false,
      frequency: false,
      pickedKeyword: "artificial-intelligence",
      rangeValue: 10,
      currentImageId: "",
      allStatistics: {},
      statistics: {},
      scrolled: false,
      wrapperHeight: 0,
      tableDistanceFromTop: 0,
      loading: false,
      currentIndex: 0,
      moveDirection: '',
      mouseOverDownload: false,
      missing: false,
    };
  },
  created() {
    this.requestData(this.pickedKeyword);
    this.allStatistics = statisticsJson;
    this.statistics = this.allStatistics[this.pickedKeyword];
  },
  mounted() {

    // this.tableDistanceFromTop = this.$refs.columnView.getBoundingClientRect().top; /* DEBUG */

    // document.addEventListener("scroll", this.handleScroll, false);

    // console.log(this.postList); /* DEBUG */
  },
  // unmounted() {
  //   // window.removeEventListener('scroll', this.handleScroll, false);
  // },
  watch: {
    currentIndex(newValue, oldValue) {
      if (newValue > oldValue) this.moveDirection = 'right'; /* DEBUG */
      if (newValue < oldValue) this.moveDirection = 'left'; /* DEBUG */
    },
    rangeValue(newValue) {
      const min = 2
      const max = 20
      if (newValue < min) this.rangeValue = min;
      if (newValue > max) this.rangeValue = max;
    },
    checkedNames(newVal, oldVal) {
      if (newVal.length == 0) this.checkedNames = oldVal;
    },
  },
  computed: {
    compHighlight: {
      get() {
        if (!this.checkedNames.includes('images')) {
          return false
        } else {
          return this.highlight
        }
      },
      set() {
        this.highlight = !this.highlight
      }
    },
    compFrequency: {
      get() {
        if (!this.checkedNames.includes('links')) {
          return false
        } else {
          return this.frequency
        }
      },
      set() {
        this.frequency = !this.frequency
      }
    }
  },
  methods: {
    // handleScroll() {
    //   try {
    //     this.tableDistanceFromTop = this.$refs.columnView.getBoundingClientRect().top; /* DEBUG */
    //   } catch (error) {
    //     return;
    //   }
    // },
    getUrl(filesize) {
      // TODO: Debug not finding images

      // if (this.loading === false) return require(`@/assets/google/${this.pickedKeyword}/img-database/${filesize}.jpg`);
      if (this.loading === false) return `/google/${this.pickedKeyword}/img-database/${filesize}.jpg`;
    },
    formatDate(input) {
      return new Date(input).toLocaleDateString("en-US", {
        // weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    },
    // TODO: figure out the loading indicator...
    async requestData(keyword) {
      this.loading = true;
      await axios.get(`/google/${keyword}/data-table.json`, { timeout: 5000 })
        .then((response) => {
          this.postList = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // console.log('Done'); /* DEBUG */
          this.loading = false;
        })
    },
    async onChange(event) {
      const data = event.target.value;
      // console.log(data);

      this.statistics = this.allStatistics[data];

      // console.log('Loading'); /* DEBUG */

      this.keywords.forEach(k => {
        let concat = k.replace(' ', '-');
        if (data === concat) {
          this.requestData(concat);
        }
      })

      // if (data === "artificial-intelligence") {
      //   this.postList = aiJson
      // }
      // // Complete this for all keywords. And make loading smooth or animated.
      // // TODO: check out all transitions and why those do not work.
      // if (data === "bitcoin") {
      //   this.requestData('bitcoin')
      // }
      // if (data === "cloud-computing") {
      //   this.requestData('cloud-computing')
      // }
      // // if (data === "bitcoin") this.postList = bitcoinJson;
      // if (data === "cloud-computing") this.postList = cloudJson;
      // if (data === "kunstmatige-intelligentie") this.postList = kiJson;
      // if (data === "hacker") this.postList = hackerJson;
      // if (data === "privacy") this.postList = privacyJson;
      // if (data === "technology") this.postList = technologyJson;
      // if (data === "robot") this.postList = robotJson;

      this.loading = false;
    },
    computeNumbers() {
      const values = Object.values(this.postList); /* DEBUG */
      const allResults = values.reduce((a, b) => a.concat(b), []);

      const keyArray = allResults.map((item) => {
        return item.link?.match(
          /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im
        )[1]
      });

      const statistics = keyArray.reduce((a, b) => {
        a[b] = a[b] + 1 || 1
        return a;
      }, {});

      // Adapted from https://attacomsian.com/blog/javascript-sort-object
      const sorted = Object.keys(statistics)
        .sort((key1, key2) => statistics[key2] - statistics[key1])
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: statistics[key]
          }),
          {}
        )

      return sorted;
    }
  },
  // mounted() {
  //   this.postList = json;
  // },
};
</script>

<style scoped lang="scss">
/* TODO: Design the layout of the entire page as a grid. So that the content grid fits nicely on the bottom together with the header grid and the header. */
/* TODO: Fix the corner div */
/* TODO: Fix the offset delay with fixed scrolling */
.v-enter-active {
  transition: opacity 0.2s ease;
}

.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

// .fade-enter-active img,
// .fade-leave-active img {
//   transition: opacity 0.3s ease-in-out;
// }

// .fade-enter-from img,
// .fade-leave-to img {
//   opacity: 0;
// }

p {
  margin: 0;
}

.downloadFile {
  text-align: center;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.downloadFile:hover {
  background-color: var(--grey--main-fade);
}

h1 {
  font-size: 1.5rem;
}

.header-bar {
  padding: 1rem;
  // display: grid;
  // align-items: stretch;
  margin-top: 1.3rem;
  padding-bottom: 1.4rem;
  // // grid-gap: 2px;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-auto-rows: 4rem 4rem;
  // grid-template-areas:
  //   "settings1 settings3 download"
  //   "settings2 settings4 download";
  background-color: var(--clr-background);
  // border: 2px solid var(--blue--main);
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: scroll;
  color: var(--blue--main);

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.headerItem-wrapper {
  border-radius: 100px;
  // width: min-content;
  // min-height: 4rem;
  padding: 0.5rem 1.5rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--clr-background);
  color: var(--blue--main);
  box-shadow: 10px 10px 19px #e8e8e8,
    -10px -10px 19px #ffffff;
  margin-right: 0.5rem;

  &.plusMinus {
    padding: 0.5rem;
  }

  h5,
  p {
    pointer-events: none;

    -webkit-user-select: none;
    user-select: none;
  }

  h5 {
    font-size: 0.8rem;
    white-space: nowrap;
    // position: absolute;
    // top: 0.5rem;
    // left: 0.5rem;
    font-family: var(--primary-font);
  }

  &:hover {
    h5:first-child:not(:only-child) {
      opacity: 1;
    }
  }

  h5:first-child:not(:only-child) {
    margin-right: 1rem;
    opacity: 0.5;
    transition: 0.4s opacity ease-in-out;
  }

  p {
    font-size: 0.9rem;
    font-family: var(--primary-font);
  }
}

.badge {
  white-space: nowrap;
}

.download-icon {
  text-align: center;
  margin-top: 1rem;
}

.options-column_width {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    font-size: 2rem;
    height: 3rem;
    width: 3rem;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--blue--main);
    transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;

    &.greyedOut {
      color: grey;
    }

    &.plus {
      cursor: zoom-in;
    }

    &.minus {
      cursor: zoom-out;
      margin-right: 0.5rem;
    }
  }
}

.greyedOut {
  color: grey;
  opacity: 0.5;
  pointer-events: none;
}

.options-keyword {
  min-width: 12rem;
  // display: grid;
  // grid-template: 1fr 1fr / 1fr 1fr 1fr 1fr;
  align-items: center;
}

.options-thingsToDisplay {
  height: min-content;
  display: grid;

  display: flex;
  flex-direction: row;

  &>*:not(:last-child) {
    margin-right: 1rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  p {
    margin-top: 0.2rem;
  }
}

.options-highlight {
  height: min-content;
  display: flex;
  flex-direction: row;

  &>*:not(:last-child) {
    margin-right: 1rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  p {
    margin-top: 0.2rem;
  }
}

#myRange {
  min-width: 12rem;
  width: 100%;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 25px;
  /* Specified height */
  background: var(--clr-background);
  border: 2px solid var(--blue--main);
  /* Grey background */
  outline: none;
  /* Remove outline */
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s;
  /* 0.2 seconds transition on hover */
  transition: opacity .2s;

  border-radius: 50px;
  position: relative;
}

.small {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: blue;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

$radius: 18px;

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: $radius;
  /* Set a specific slider handle width */
  height: $radius;
  /* Slider handle height */
  background: var(--blue--main);
  border: none;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */

  border-radius: 20px;
}

.slider::-moz-range-progress {
  background-color: var(--blue--main);
  height: $radius;
  border-radius: 20px 0 0 20px;
}


.slider::-moz-range-thumb {
  width: $radius;
  /* Set a specific slider handle width */
  height: $radius;
  /* Slider handle height */
  background: var(--blue--main);
  border: none;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 20px;

}

fieldset {
  display: block;
  padding: none;
  border: none;
  margin: none;

  &>div {
    transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
  }
}

label {
  white-space: nowrap;
  display: inline-block;
  line-height: 0;
  /* TODO: text spacing */
}

input {
  accent-color: var(--blue--main);
  margin-right: 0.5rem;
}

/* toggle-pill-bw */
input[type="checkbox"] {
  display: none;
}

.toggler-wrapper {
  display: block;
  width: 45px;
  height: 25px;
  cursor: pointer;
  position: relative;
}

.toggler-wrapper input[type="checkbox"] {
  display: none;
}

.toggler-wrapper input[type="checkbox"]:checked+.toggler-slider {
  background-color: var(--blue--main);
}

.toggler-wrapper .toggler-slider {
  background-color: rgba(200, 200, 200, 0.5);
  position: absolute;
  border-radius: 100px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.toggler-wrapper .toggler-knob {
  position: absolute;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*Style 1*/

.toggler-wrapper.style-1 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
  left: calc(100% - 19px - 3px);
}

.toggler-wrapper.style-1 .toggler-knob {
  width: calc(25px - 6px);
  height: calc(25px - 6px);
  border-radius: 50%;
  left: 3px;
  top: 3px;
  background-color: #fff;
}

/* END Style 1*/

.tableContainer.highlighting:hover .resultImage:not(.selected) {
  opacity: 0.1;
  filter: saturate(0);
}

.selected {
  // border: 1px solid var(--clr-background);
}

.fullHeight {
  height: 100vh;
}

.positionOnPage {
  /* width: 100%; */
  width: min-content;
  padding: 0.5rem;
  font-size: var(--font-caption);
  background-color: var(--blue--main);
  z-index: 3;
  border-radius: 0.5rem;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: var(--blue--main);
  font-family: var(--primary-font);
  font-weight: 400;
  color: white;
}

.positionOnPage.inLine {
  background-color: transparent;
  color: var(--blue--main);
  width: 100%;
  font-size: 1rem;
  position: static;
  top: 0;
  left: 0;
  border-radius: 0;
  opacity: 0.5;
}

.positionOnPage span {
  font-size: 1rem;
  display: inline-block;
  /* border: 2px solid blue; */
}

.title-info {
  margin-top: 1.4rem;
  padding-left: 2.3rem;
  color: var(--blue--main);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.titleAndButton {
  flex-direction: row;
  display: flex;
  justify-content: baseline;
  align-items: baseline;

  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;

  h2 {
    font-size: 2rem;
  }

  p {
    margin-left: 1rem;
    font-size: 1rem;
  }
}

.title-info p {
  margin-top: 0.5rem;
}

select {
  background-color: white;
  border: 2px solid var(--blue--main);
  border-left: none;
  border-right: none;
  border-top: none;
  color: var(--blue--main);
  padding: 0.5rem;
  width: 100%;
  /* border-radius: 0.2rem; */
}

.image-explorer {
  height: 100%;
  width: 100%;
}

.column-view {
  display: block;
  margin-top: 1rem;
  overflow: auto;
  scroll-snap-type: both mandatory;
  scroll-padding-top: 3rem;
  scroll-padding-left: 3rem;
}

.loading-notice {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--secondary-font);
  font-size: 2rem;
  color: var(--blue--main);
  padding: 10rem;
}

$padding: 3rem;

.tableContainer {
  max-height: 100vh;
  max-width: 100vw;
  overflow: scroll;
  scroll-snap-type: both mandatory;
  scroll-padding: $padding 0 0 $padding;
}

table {
  height: 1px;
  border-collapse: separate;
}

tr,
td {
  height: 100%;
  scroll-snap-align: start;
  background-clip: padding-box;
}

tbody tr:nth-of-type(1) th:nth-child(1) {
  border-top: none;
}

td p {
  max-width: 100%;
}

th[scope="row"] {
  color: var(--blue--main);
  // padding: var(--flow-space);
  vertical-align: middle;
  text-align: center;
  border-top: 1px solid var(--blue--main);
  border-bottom: 1px solid var(--blue--main);

  h4 {
    border: none;
    position: relative;
    top: 0;
    padding: 0;
    height: auto;
  }
}

table thead th {
  background-color: var(--clr-background);
  z-index: 9;
  height: $padding;
  position: sticky;
}


table tbody th {
  background-color: var(--clr-background);
  z-index: 9;
  min-width: $padding;
  position: sticky;
}

thead th {
  top: 0;
}

thead th:first-child {
  left: 0;
  z-index: 10;
}

tbody th {
  left: 0;
}

.cornerPoint {
  cursor: nw-resize;

  padding: 0.9rem;

  border-bottom: 2px solid var(--blue--main);

  border-right: 2px solid var(--blue--main);

  &:hover {
    background-color: var(--blue--main);

    img {
      filter: grayscale(100%) sepia(100%) brightness(100);
    }
  }
}

tbody>tr:nth-child(1) th {
  border-top: 2px solid var(--blue--main);
}

thead>tr:nth-child(1) th:nth-child(2) {
  border-left: none;
}

// thead>tr:nth-child(1) th:nth-child(1) {
//   border-right: 1px solid var(--blue--main);
//   border-bottom: 1px solid var(--blue--main);
// }

th[scope="col"]:nth-child(n+2) {
  border-left: 1px solid var(--blue--main);
  border-right: 1px solid var(--blue--main);
  color: var(--blue--main);
  text-align: center;
  vertical-align: middle;
  font-size: 0.8rem;
  padding: 0.5rem;
  line-height: 1.2;

  // padding: var(--flow-space);
}

tr:nth-child(odd) td:nth-child(even) {
  background-color: var(--grey--main-fade);
}

tr:nth-child(even) td:nth-child(odd) {
  background-color: var(--grey--main-fade);
}

td {
  vertical-align: top;
}

.imageWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  // transition: 0.2s width ease-in-out;
}

.img-inner-wrapper {
  opacity: 1;
  background: var(--clr-background);
}

/* TODO: fix the display of the height */
.imageWrapper.blank {
  background-color: var(--grey--main-fade);
  color: var(--blue--main);

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--clr-background);

  -webkit-user-select: none;
  user-select: none;

  p {
    font-family: var(--primary-font);
    font-size: 0.8em;
    pointer-events: none;
  }

  &:hover {
    color: var(--grey--main);
    filter: saturate(0);
  }
}

.imageWrapper:hover .faded {
  opacity: 1;
  filter: saturate(1);
}

.imageWrapper img {
  object-fit: cover;
  transition: 0.2 width ease-in-out, 0.2s height ease-in-out;

  &.faded {
    opacity: 0.2;
    filter: saturate(0);
  }

  &.missing {
    background-color: var(--blue--main);
  }
}

.resultImage {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.1s opacity ease-in-out, 0.1s filter ease-in-out;
}

p {
  font-size: 20px;
}

p,
h2 {
  text-overflow: ellipsis;
}

.title {
  padding: 0.5rem;
  padding-top: 0;
  margin-top: 0.5rem;
  padding-bottom: 0.9rem;
  font-size: 0.8rem;
  line-height: 1.15;
  width: 100%;
  display: block;
  max-height: min-content;

  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis

}

.linkToPage {
  position: relative;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
  padding-bottom: 0.6rem;
  font-family: var(--primary-font);
  font-size: 0.95rem;
  line-height: 1;
  background-color: rgba(12, 24, 189, calc(var(--frequency) / var(--max-frequency)));
  transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out;

}

.linkToPage.heatmap {
  color: white;
}

.linkToPage::after {
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  transform: scaleY(0);
  height: 100%;
  bottom: 0;
  left: 0rem;
  background-color: var(--blue--main);
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;

  // .right & {
  //   transform-origin: bottom right;
  // }

  // .left & {
  //   transform-origin: bottom left;
  // }
}

.linkToPage:hover {
  color: var(--clr-background);
}

.linkToPage:hover::after {
  transform: scaleX(1);
  // transform-origin: top center;

  // .right & {
  //   transform-origin: bottom left;
  // }

  // .left & {
  //   transform-origin: bottom right;
  // }
}

a:link,
a:visited {
  color: var(--blue--main);
}

.desktop-notice {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: grid;
  align-items: center;
  justify-content: center;
}

.desktop-notice__p {
  color: var(--blue--main);
  font-size: 2rem;
  text-align: center;
  position: relative;

  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 10rem;
    height: 10rem;
    border-radius: 100%;
    background-color: blue;
    opacity: 0.5;
    filter: blur(100px);
  }
}
</style>
